import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';

// import { MyAccountComponent } from './myaccount/myaccount.component';
// import { ProfileComponent } from './myaccount/profile.component';
// import { PreferencesComponent } from './myaccount/preferences.component';
// import { SignoutComponent } from './myaccount/signout.component';

import { PageNotFoundComponent } from './page-not-found.component';

import { MapboxCostsCalculatorComponent } from './utils/mapbox-costs-calculator.component';

function pagesUrlMatcher(url: UrlSegment[]) {
    if (url.length >= 1) {
        let path = url[0].path;
        return ['terms', 'privacy', 'support', 'about', 'faq', 'mapping'].includes(path)
            ? ({ consumed: url }) : null;
    }
    return null;
}

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    },

    { path: 'price-calculator', component: MapboxCostsCalculatorComponent },

    // ATTENTION: make sure all the /resort routes described here are also
    // generated in the files below:
    //
    // - server/web/html-generator-main.py
    // - server/web/templates/sitemap-resort.xml
    {
        path: 'resorts',
        loadChildren: () => import('./skiresorts/skiresorts.module').then(m => m.SkiResortsModule)
    },

    {
        path: 'resort',
        loadChildren: () => import('./skiresorts/skiresort.module').then(m => m.SkiResortModule)
    },

    {
        path: 'myaccount',
        loadChildren: () => import('./myaccount/myaccount.module').then(m => m.MyAccountModule)
    },

    {
        path: 'blog',
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
    },

    {
        matcher: pagesUrlMatcher,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },

    // Wildcard route for a 404 page
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 64],  // scroll offset when scrolling to an element (optional)
            // scrollPositionRestoration: 'enabled', // restores the previous scroll position on backward navigation

            /* initialNavigation: 'enabled'*/
        }),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
