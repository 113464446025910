import {
    Component, OnInit, OnDestroy, ViewChild, ElementRef, Input,
    ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit,
} from '@angular/core';
import {
    Router, NavigationEnd, Event,
} from '@angular/router';
import { Location } from '@angular/common';

import { Observable, Subscription, OperatorFunction, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AuthService } from "../auth.service";
import { User } from '../datamodel';

import { UserResponse_Route } from '../proto/requests';

import { ResortsService } from '../resorts.service';
import { UrlService } from '../utils/url.service';
import { ResortBrief } from '../datamodel';
import { NavbarService } from './navbar.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
    private subscription: Subscription = new Subscription();

    user?: User;

    isCollapsed: boolean = true;
    isSignedIn: boolean = false;

    displayName?: string;

    extraRoutes: UserResponse_Route[] = [];
    extraMenuTitle?: string;

    @ViewChild('searchBox') searchBox?: ElementRef;
    public isSearchBoxVisible: boolean = false;
    @Input() searchBoxText: string = '';
    private searchBoxResort?: ResortBrief;

    @Input() showExtraLinks: boolean = false;

    constructor(
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private location: Location,

        public auth: AuthService,
        public url: UrlService,
        private resorts: ResortsService,
        public navbar: NavbarService,
    ) {
        this.subscription.add(
            combineLatest([
                this.auth.extraRoutes$,
                this.auth.extraMenuTitle$,
                this.auth.getUserEventEmitter(),
            ]).subscribe((values: any[]) => {
                this.init();
            })
        );
        this.subscription.add(
            this.auth.getUserEventEmitter()
                .subscribe((user: User) => this.handleUserUpdate(user))
        );

        this.subscription.add(
            this.router.events.pipe(
                filter((event: Event) => event instanceof NavigationEnd)
            ).subscribe(() => this.init())
        );
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        // this.init();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private init() {
        console.log("Navbar init()");
        this.handleUserUpdate(this.auth.user);
        this.handleExtraRoutes(this.auth.extraRoutes);
        this.extraMenuTitle = this.auth.extraMenuTitle;
        // this.changeDetector.detectChanges();
    }

    private handleUserUpdate(user?: User) {
        console.log('handleUserUpdate user = ', user);
        this.user = user;
        if (user && Object.keys(user).length) {
            this.isSignedIn = true;
            this.displayName = user.firstName || user.displayName;

        } else {
            this.isSignedIn = false;
            this.displayName = undefined;
        }
        this.changeDetector.detectChanges();
    }

    private handleExtraRoutes(extraRoutes: UserResponse_Route[]) {
        console.log("Got extra routes", extraRoutes);
        this.extraRoutes = extraRoutes;
        this.changeDetector.markForCheck();
    }

    navbarOpen = false;

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
    }

    appleSignIn() {
        this.auth.appleSignIn();
    }

    googleSignIn() {
        this.auth.googleSignIn();
    }

    facebookSignIn() {
        this.auth.facebookSignIn();
    }

    twitterSignIn() {
        this.auth.twitterSignIn();
    }

    microsoftSignIn() {
        this.auth.microsoftSignIn();
    }

    signOut() {
        this.auth.signOut();
        this.handleUserUpdate(undefined);
    }

    toggleSearchBox() {
        console.log("toggleSearchBox");
        this.isSearchBoxVisible = !this.isSearchBoxVisible;
        if (this.isSearchBoxVisible && this.searchBox) {
            setTimeout(() => {
                this.searchBox!.nativeElement.focus();
            }, 0);
        }
    }

    doneWithSearchBox(clear: boolean = false) {
        console.log("done with search box", this.searchBoxResort, "clear", clear);
        this.toggleSearchBox();
        if (clear) {
            console.log("Clearing the search box text");
            this.searchBoxText = '';
        } else {
            console.log("Must navigate to ", this.searchBoxResort);
            // TODO: navigate to search results page
            if (this.searchBoxResort) {
                this.navigateToResort(this.searchBoxResort);
            }
        }
    }

    searchBoxItemSelected(value: ResortBrief) {
        console.log("searchBoxItemSelected, must navigate", value);
        this.searchBoxResort = value;
        if (this.searchBoxResort) {
            this.navigateToResort(this.searchBoxResort);
        }
    }

    private navigateToResort(resort: ResortBrief) {
        this.searchBoxResort = undefined;
        this.searchBoxText = '';
        console.log("Must navigate to",
            this.navbar.searchUrlPrefix + this.url.urlForResort(resort));
        this.router.navigateByUrl(
            this.navbar.searchUrlPrefix + this.url.urlForResort(resort));
        this.changeDetector.detectChanges();
    }

    searchResorts: OperatorFunction<string, ResortBrief[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => {
                console.log("Have to fetch resorts with term", term,
                    "search engine", this.resorts.searchEngine);
                return term.length < 2 ? []
                    : this.resorts.searchEngine?.searchResorts(term)
            })
        ) as any;

    nameFromResortBrief = (r: ResortBrief) => r.name;

    classForResortsLink() {
        let components = this.location.path().split('/');
        console.log("Navbar URL components", components);
        if (components[1]?.startsWith('resort')) {
            // console.log("Returning active-link");
            return 'active-link';
        } else {
            return '';
        }
    }

    classForBlogLink() {
        let path = this.location.path();
        return path.startsWith('/blog') && !path.startsWith('/blog/admin')
            ? 'active-link' : '';
    }

    classForExtraRoute(route: string) {
        let path = this.location.path();
        return path.startsWith(route) ? 'active-link' : '';
    }

    breadcrumbs(value: any) {
        let result = [];
        if (value instanceof ResortBrief) {
            let resort = value as ResortBrief;
            result.push(resort.country.continent);
            result.push(resort.country);
            if (resort.state) {
                result.push(resort.state);
            }
        }
        return result;
    }

    urlForObject(object: any) {
        console.log("Rendering search box result", this.navbar.searchUrlPrefix);
        let url = this.url.urlForModelObject(object, this.navbar.searchUrlPrefix);
        console.log("Search box result got URL", url);
        return url;
    }
}
