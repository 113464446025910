import { Component, OnInit, OnDestroy, VERSION, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { LazyLoaderService } from './lazy-loader.service';

import { AnchorService } from './anchor.service';
import { environment } from '../environments/environment';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Jollyturns';
    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        // Adding the lazy loader service here will force the creation
        // of the service which listens to authentication changes and
        // inserts new routes for logged in users. The labeler, snow
        // and blog admin module rely on it.
        private lazyLoaderService: LazyLoaderService,
        private anchorService: AnchorService,
    ) {
        this.subscription.add(
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    gtag('config', environment.googleAnalytics,
                        {
                            'page_path': event.urlAfterRedirects
                        }
                    );
                }
            })
        );
    }

    ngOnInit() {
        console.log("Angular version", VERSION.full);

        // if (typeof Worker !== 'undefined') {
        //     // Create a new
        //     console.log("import.meta.url", import.meta.url);
        //     const worker = new Worker(new URL('./app.worker', import.meta.url));
        //     console.log("Worker created", worker);

        //     // const worker = new Worker('./app.worker', { name: 'app', type: 'module' });
        //     // const worker = new Worker('./app.worker', { type: "module" });
        //     worker.onmessage = ({ data }) => {
        //         console.log(`page got message: ${data} from worker`);
        //     };
        //     worker.postMessage('hello');
        //     console.log("Main thread posted message hello to worker");
        // } else {
        //     // Web Workers are not supported in this environment.
        //     // You should add a fallback so that your program still executes correctly.
        // }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event) {
        this.anchorService.interceptClick(event);
    }
}
