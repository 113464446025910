import { Component, OnInit, } from '@angular/core';

@Component({
    selector: 'app-mapbox-costs-calculator',
    templateUrl: './mapbox-costs-calculator.component.html',
    styleUrls: ['./mapbox-costs-calculator.component.scss']
})
export class MapboxCostsCalculatorComponent implements OnInit {

    public quantity: number = 50;
    public cost: number = 0;

    constructor() { }

    ngOnInit(): void {
        this.quantityChanged();
    }

    quantityChanged() {
        let rangeSplits = [0, 50, 100, 200, 1000, Number.MAX_VALUE];
        let rangeCost = [0, 0, 5, 4, 3, 2];
        console.log("Quantity changed", this.quantity);
        let totalCost = 0,
            quantity = this.quantity;

        console.log("Quantity", quantity);
        for (let i = 1; i < rangeSplits.length; i++) {
            quantity = Math.min(this.quantity, rangeSplits[i]) - rangeSplits[i - 1];
            console.log("Range", i, ", looking at range split", rangeSplits[i],
                ", quantity", quantity);
            let costInRange = quantity * rangeCost[i];
            console.log("range", i, ", cost in range", costInRange);
            totalCost += costInRange;
            if (this.quantity <= rangeSplits[i]) {
                break;
            }
        }
        // if (totalCost > 0) {
        //     totalCost += 100;
        // }
        this.cost = totalCost + 100;
    }
}
