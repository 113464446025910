import { Injectable, EventEmitter } from '@angular/core';
import { classToClass } from 'class-transformer';

import { UtilsService } from './utils.service';

export class ProgressReport {
    id: string;
    current: number = 0;
    total: number = 100;
    done: boolean = false;

    constructor(workId: string, current: number = 0, total: number = 100) {
        this.id = workId;
        this.current = current;
        this.total = total;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ProgressBarService {

    private _show: boolean = false;
    public get show() { return this._show; }

    private _percentage: number = 0;
    public get percentage() { return this._percentage; }

    private _percentage$: EventEmitter<number> = new EventEmitter();
    public get percentage$() { return this._percentage$; }

    private _show$: EventEmitter<boolean> = new EventEmitter();
    public get show$() { return this._show$; }

    private workInProgress: Map<string, ProgressReport> = new Map();

    constructor(
        private utils: UtilsService,
    ) { }

    private setShow(value: boolean) {
        // console.log("ProgressBarService show:", value);
        this._show = value;
        this._show$.emit(value);
    }

    private setPercentage(value: number) {
        // console.log("ProgressBarService percentage:", value);
        this._percentage = value;
        this._percentage$.emit(value);
    }

    updateWork(work: ProgressReport) {
        // console.log("ProgressBarService work:", work);
        if (work.current >= work.total ||
            Math.abs(work.current / work.total - 1) < 1e-3) {
            work.done = true;
        }
        if (work.done) {
            this.workInProgress.delete(work.id);
        } else {
            this.workInProgress.set(work.id, work);
        }
        if (this.workInProgress.size == 0) {
            this.setShow(false);
            this.setPercentage(100);
        } else {
            this.setShow(true);

            // Update the value of progress and show.
            let current = 0;
            let total = 0;
            this.workInProgress.forEach((work) => {
                // console.log("Looking at work", work);
                current += work.current;
                total += work.total;
            });
            // console.log("Computed current", current, "total", total);
            let p = this.utils.round(current / total * 100, 1);
            // console.log("Percentage", p);
            if (Math.abs(p - 100) < 1e-3 || p > 100) {
                p = 100;
            }
            if (p == 100) {
                this.setShow(false);
                this.workInProgress.clear();
            }
            this.setPercentage(p);
        }
    }
}
