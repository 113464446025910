<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/" routerLinkActive="active" name="Home">
    <img src="assets/png/jollyturns-logo-328x120.png"
         alt="Jollyturns"
         class="brand-image"
         width="164"
         height="60"
         />
  </a>

  <button class="navbar-toggler"
          type="button"
          (click)="toggleNavbar()"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- <div class="container-fluid"> -->
    <div class="collapse navbar-collapse"
         [ngClass]="{ 'show': navbarOpen }">

      <!-- justify-content-end -->
      <ul class="navbar-nav ml-auto justify-content-end">

        <li class="nav-item mr-2"
            [hidden]="!isSearchBoxVisible"
            >
          <input #searchBox
                 [(ngModel)]="searchBoxText"
                 type="text"
                 class="form-control"
                 placeholder="Ski resort"
                 aria-label="Ski resort"
                 (focus)="isSearchBoxVisible"
                 (keyup.enter)="doneWithSearchBox()"
                 (keydown.escape)="doneWithSearchBox(true)"
                 [ngbTypeahead]="searchResorts"
                 [inputFormatter]="nameFromResortBrief"
                 [resultFormatter]="nameFromResortBrief"
                 (selectItem)="searchBoxItemSelected($event.item)"
                 [resultTemplate]="rt"
                 >
        </li>

        <ng-template #rt let-r="result" let-t="term">
          <div *ngIf="r">
            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            <span> (</span>
            <span *ngFor="let b of breadcrumbs(r); last as isLast">
              <span class="link"
                    [routerLink]="urlForObject(b)"
                    >{{b.name}}</span>
              <i i-bs
                 *ngIf="!isLast"
                 name="chevron-right"
                 aria-hidden="true">
              </i>
            </span>)
          </div>
          <div *ngIf="!r">
            <span>No results</span>
          </div>
        </ng-template>

        <li class="nav-item text-right"
            (click)="toggleSearchBox()"
            >
          <i-bs class="btn btn-outline-secondary"
                name="search"
                width="22px"
                height="22px"
                >
          </i-bs>
        </li>

        <li ngbDropdown
            class="nav-item text-right"
            *ngIf="extraMenuTitle || extraRoutes && extraRoutes.length > 0">
          <div class="nav-link unselectable" ngbDropdownToggle
               display="dynamic"
               style="cursor: pointer"
               role="button"
               >{{extraMenuTitle}}
          </div>
          <div ngbDropdownMenu
               class="dropdown-menu dropdown-menu-right">
            <div *ngFor="let route of extraRoutes"
                 class="nav-item"
                 >
              <a class="nav-link" routerLink="/{{route.url}}" [ngClass]="classForExtraRoute('/' + route.url)">{{route.label}}</a>
            </div>
          </div>
        </li>

        <li class="nav-item text-right">
          <a class="nav-link"
             routerLink="/resorts"
             [ngClass]="classForResortsLink()"
             name="Resorts"
             >Resorts</a>
        </li>

        <li class="nav-item text-right">
          <a class="nav-link"
             routerLink="/blog"
             [ngClass]="classForBlogLink()"
             name="Blog"
             >Blog</a>
        </li>

        <li class="nav-item text-right" *ngIf="showExtraLinks">
          <a class="nav-link"
             routerLink="/terms"
             [ngClass]="classForExtraRoute('/terms')"
             name="Terms"
             >Terms</a>
        </li>

        <li class="nav-item text-right" *ngIf="showExtraLinks">
          <a class="nav-link"
             routerLink="/privacy"
             [ngClass]="classForExtraRoute('/privacy')"
             name="Privacy"
             >Privacy</a>
        </li>

        <li class="nav-item text-right" *ngIf="showExtraLinks">
          <a class="nav-link"
             routerLink="/faq/privacy"
             [ngClass]="classForExtraRoute('/faq')"
             name="FAQ"
             >FAQ</a>
        </li>

        <li class="nav-item text-right" *ngIf="showExtraLinks">
          <a class="nav-link"
             routerLink="/support"
             [ngClass]="classForExtraRoute('/support')"
             name="Support"
             >Support</a>
        </li>

        <li class="nav-item text-right" *ngIf="showExtraLinks">
          <a class="nav-link"
             routerLink="/about"
             [ngClass]="classForExtraRoute('/about')"
             name="About"
             >About</a>
        </li>


        <li class="nav-item text-right" ngbDropdown [hidden]="isSignedIn">
          <div class="nav-link unselectable" ngbDropdownToggle
               display="dynamic"
               style="cursor: pointer"
               role="button"
               >Sign In
          </div>

          <div ngbDropdownMenu
               class="dropdown-menu dropdown-menu-right text-center">
            <span ngbDropdownItem>
              <app-loginbutton [type]="'apple'" (action)="appleSignIn()"></app-loginbutton>
            </span>

            <span ngbDropdownItem>
              <app-loginbutton [type]="'google'" (action)="googleSignIn()"></app-loginbutton>
            </span>

            <span ngbDropdownItem>
              <app-loginbutton [type]="'facebook'" (action)="facebookSignIn()"></app-loginbutton>
            </span>

<!--
            <span ngbDropdownItem>
              <app-loginbutton [type]="'twitter'" (action)="twitterSignIn()"></app-loginbutton>
            </span>
            -->

            <span ngbDropdownItem>
              <app-loginbutton [type]="'microsoft'" (action)="microsoftSignIn()"></app-loginbutton>
            </span>

          </div>
        </li>

        <li class="nav-item text-right" *ngIf="user">
          <a class="nav-link">
            <img [src]="user.userSmallImage()" class="rounded-circle mx-auto" alt="User's image"/>
            <a class="nav-text" routerLink="/myaccount/profile">&nbsp;&nbsp;{{displayName}}</a>
          </a>
        </li>

      </ul>
    </div>
  <!-- </div> -->


</nav>
