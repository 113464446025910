import { Injectable } from '@angular/core';

import { Observable, Subscription, bindCallback } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResortsService } from '../resorts.service';
import { World, ResortBrief } from '../datamodel';

import { Index, Document, SimpleDocumentSearchResultSetUnit } from 'flexsearch';

export class SearchEngine {
    // private subscription: Subscription = new Subscription();

    private world: World;
    private resortsIndex: Document<ResortBrief> | undefined;

    constructor(world: World) {
        console.log("Search service initialized with world", world);
        this.world = world;
    }

    private init() {
        var start = performance.now();
        this.resortsIndex = new Document({
            document: {
                id: "resortUid",
                index: [
                    {
                        field: "name",
                        // tokenize: "forward",
                        tokenize: "full",
                        encode: (word: string) => [word.removeDiacritics().toLowerCase()],
                    },
                    {
                        field: "concatenatedPreviousNames",
                        // tokenize: "forward",
                        tokenize: "full",
                        encode: (word: string) => [word.removeDiacritics().toLowerCase()],
                    },
                ],
            },
            cache: true,
            // encode: 'advanced',
            // language: "en",
        });
        for (let resort of this.world.resorts) {
            this.resortsIndex.add(resort);
        }
        let end = performance.now();
        let duration = end - start;
        console.log(`Search index constructed in ${duration} milliseconds`);
    }

    public searchResorts(value: string): (ResortBrief | null)[] {
        var start = performance.now();
        if (!this.resortsIndex) {
            this.init();
        }

        value = value.toLowerCase();
        let results: any[] = [];
        for (let index of ["name", "concatenatedPreviousNames"]) {
            let current = this.resortsIndex!.search(value, {
                limit: 10,
                bool: "and",
                index: [index],
            });
            // console.log(`Looking for ${value} in ${index}, found`, current);
            results = results.concat(current);
        }
        let resorts: ResortBrief[] = [];
        // console.log("Got results", results);
        for (let index of results) {
            for (let resortUid of index.result) {
                let resort = this.world.getResortWithUid(resortUid as number);
                if (!resorts.some((r: ResortBrief) => r.resortUid == resort.resortUid)) {
                    resorts.push(resort);
                }
            }
            let end = performance.now();
            let duration = end - start;
            console.log(`Search performed in ${duration} milliseconds`);
        }
        console.log("Return search results", resorts);
        return resorts.length == 0 ? [null] : resorts;
    }
}
