import { Component, OnInit, HostListener } from '@angular/core';

import { WindowService } from './utils/window.service';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    notice?: string;

    constructor(
        private window: WindowService,
    ) {
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.window.onResize(event);
        this.init();
    }

    @HostListener('window:orientationchange', ['$event'])
    onScreenOrientationChange(event: any) {
        console.log("Screen orientation change", event);
        this.window.onResize(event);
        this.init();
    }

    ngOnInit(): void {
        this.init();
    }

    private init() {
        let year: number = new Date().getUTCFullYear();
        this.notice = `Jollyturns © 2013-${year}`;
        if (environment.showWindowSize) {
            this.notice += `. (${this.window.width} x ${this.window.height})`;
        }
    }
}
