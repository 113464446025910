import { EventEmitter, Injectable } from '@angular/core';
import {
    HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders, HttpEvent,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry, concatMap } from 'rxjs/operators';
import { EMPTY, pipe } from 'rxjs';

import { environment, hostname } from '../environments/environment';
import { User } from './datamodel';
import { XSRFService } from './xsrf.service';

import { Webapi2Client } from './proto/webapi2-client';
import { Webapi2authClient } from './proto/webapi2Auth-client';
import {
    Empty, AllResorts2, GetResortsRequest, GetResortsResponse2,
    GetHeatmapRequest, GetHeatmapResponse,
    UserRequest, UserResponse,
    CheckoutSessionRequest, CheckoutSessionResponse,
    PurchaseCompletedRequest, PurchaseCompletedResponse,
    AllResortBoundaries, DeleteUserResponse,
} from './proto/requests';

import { UtilsService } from './utils.service';


@Injectable({
    providedIn: 'root'
})
export class BackendService {
    baseUrl = environment.baseUrl;
    tilesUrl = environment.tilesUrl;
    heatmapsUrl = environment.heatmapsUrl;

    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        })
    };

    constructor(
        private http: HttpClient,
        private webapi2: Webapi2Client,
        private webapi2auth: Webapi2authClient,
        private xsrf: XSRFService,
        private utils: UtilsService,
    ) {
        console.log(`Using hostname ${hostname} as backend`);
        this.webapi2.setBaseUrl(this.baseUrl);
        this.webapi2auth.setBaseUrl(this.baseUrl, 'auth');
        // let url = this.baseUrl + '/ping';
    }

    handleAppleLogin(data: any): Observable<User> {
        let handleAppleLoginURL = this.baseUrl + '/handle-apple-login';
        console.log("Got response from Apple: ", data);
        let payload: any = {};
        for (const [k, v] of Object.entries(data)) {
            payload[k] = v;
        }
        console.log("Sending payload", payload);
        return this.http.post<User>(
            handleAppleLoginURL,
            { data: payload },
            this.httpOptions
        ).pipe(
            catchError(this.handleError)
        );
    }

    appleLoginRedirectUrl(): string {
        console.log('apple login url', this.baseUrl);
        return this.baseUrl + '/applelogin';
    }

    handleLogout(): Observable<User> {
        let logoutURL = this.baseUrl + '/logout';
        // console.log(`Making request to ${logoutURL}`);
        return this.http.post<User>(logoutURL,
            {},
            this.httpOptions
        ).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            if (error.status != 200) {
                console.error(
                    `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`);
            } else {
                return EMPTY;
            }
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };

    fetchAllResorts(): Observable<AllResorts2> {
        console.log("Making request to fetch all resorts");
        return this.webapi2.FetchAllResortsProgressUpdate({} as Empty);
    }

    fetchAllResortsProgress(): Observable<HttpEvent<ArrayBuffer>> {
        console.log(`Making request to fetch all resorts progress ${hostname}`);
        return this.webapi2.FetchAllResortsProgress({} as Empty);
    }

    fetchResort(resortUid: number): Observable<GetResortsResponse2> {
        console.log("Making request to resort_uid", resortUid);
        let request: GetResortsRequest = { resortUid: resortUid };
        return this.webapi2.FetchResortProgressUpdate(request);
    }

    urlForHeatmapImage(identifier: string): string {
        return this.baseUrl + '/resort-heatmap-image/' + identifier;
    }

    fetchHeatmap(resortUid: number): Observable<GetHeatmapResponse> {
        console.log("Making request to fetch heatmap for resort_uid",
            resortUid);
        let request: GetHeatmapRequest = { resortUid: resortUid };
        return this.webapi2.FetchHeatmap(request);
    }

    fetchBoundaries(): Observable<AllResortBoundaries> {
        console.log("Making request to fetch all resort boundaries");
        return this.webapi2.FetchBoundariesProgressUpdate({} as Empty);
    }

    fetchUser(): Observable<UserResponse> {
        console.log("Making request to fetch user");
        return this.webapi2auth.FetchUser({});
    }

    saveUser(request: UserRequest): Observable<UserResponse> {
        return this.webapi2auth.SaveUser(request);
    }

    deleteUser(): Observable<DeleteUserResponse> {
        console.log("Making request to delete user");
        return this.webapi2auth.DeleteUser({});
    }

    createCheckoutSession(request: CheckoutSessionRequest):
        Observable<CheckoutSessionResponse> {
        return this.webapi2auth.CreateCheckoutSessionProgressUpdate(request);
    }

    purchaseCompleted(request: PurchaseCompletedRequest):
        Observable<PurchaseCompletedResponse> {
        return this.webapi2auth.PurchaseCompletedProgressUpdate(request);
    }

    purchaseCancelled(request: PurchaseCompletedRequest):
        Observable<PurchaseCompletedResponse> {
        return this.webapi2auth.PurchaseCancelledProgressUpdate(request);
    }

    urlForMapImage(identifier: string): string {
        return this.baseUrl + '/map/' + identifier;
    }

    fetchMapImage(identifier: string): Observable<HttpEvent<Blob>> {
        return this.http.get(this.urlForMapImage(identifier),
            {
                responseType: 'blob',
                reportProgress: true,
                observe: "events",
            });
    }

    private tileRegex: RegExp =
        new RegExp('^https://tile/\(?<z>\\d+\)/\(?<x>\\d+\)/\(?<y>\\d+\)$');
    private heatmapsRegex: RegExp =
        new RegExp('^https://heatmaps/\(?<z>\\d+\)/\(?<x>\\d+\)/\(?<y>\\d+\)$');

    // Transform the request that mapbox makes to fetch the heatmap
    // image by adding the XSRF token.
    mapboxTransformRequest(url: string, resourceType: string) {
        if (url.startsWith("//")) {
            let urlObj: URL = new URL(url, this.baseUrl);
            url = urlObj.href;
        }
        if (url.startsWith('https://tile/')) {
            let match = this.tileRegex.exec(url);
            if (!(match && match.groups)) {
                console.log("Bad URL", url);
                return { url: url };
            }

            let x = parseInt(match.groups.x, 10);
            let y = parseInt(match.groups.y, 10);
            let z = parseInt(match.groups.z, 10);

            // console.log("Making request for (x, y, z)", x, y, z);
            let encoded = this.utils.encodeTileXYZ(x, y, z);
            let newUrl = encoded ? this.tilesUrl + "/" + encoded : url;
            return { url: newUrl };
        }
        else if (url.startsWith('https://heatmaps/')) {
            let match = this.heatmapsRegex.exec(url);
            if (!(match && match.groups)) {
                console.log("Bad URL", url);
                return { url: url };
            }

            let x = parseInt(match.groups.x, 10);
            let y = parseInt(match.groups.y, 10);
            let z = parseInt(match.groups.z, 10);

            // console.log("Making request for (x, y, z)", x, y, z);
            let encoded = this.utils.encodeTileXYZ(x, y, z);
            let newUrl = encoded ? this.heatmapsUrl + "/" + encoded : url;
            return { url: newUrl };
        }
        else if (url.startsWith(this.baseUrl)) {
            let request = {
                url: url,
                headers: { 'X-XSRFToken': this.xsrf.xsrf },
            };
            return request;
        }
        return { url: url }
    }
}
