// import { Location } from '@angular/common';
import {
    Injectable, EventEmitter, Renderer2, RendererFactory2, Inject,
    ViewEncapsulation,
} from '@angular/core';
import { Meta, } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import {
    Router, ActivatedRoute, NavigationStart, NavigationEnd, Event, UrlTree,
    Params, ParamMap, UrlSegment, UrlSegmentGroup,
} from '@angular/router';

import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { hostname, environment } from "../../environments/environment";
import { GPSPoint, Resort, Map } from '../datamodel';

@Injectable({
    providedIn: 'root'
})
export class WindowService {
    windowSize$: EventEmitter<[number, number]> = new EventEmitter();
    deviceTypeChanged$: EventEmitter<boolean> = new EventEmitter();

    _width: number = (window as any).innerWidth;
    get width() { return this._width; }

    _height: number = (window as any).innerHeight;
    get height() { return this._height; }

    get tabletWidth() { return 1194; }

    private queryParams?: Params;

    private wasLastDeviceTypeSmall: boolean = this._width < 1194;

    private canonicalLink: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private meta: Meta,
        // private location: Location,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: any,
    ) {
        let renderer: Renderer2 = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });

        let baseRef = document.getElementById('_baseRef');
        console.log("WindowService baseRef element", baseRef);

        this.canonicalLink = renderer.createElement('link');
        this.canonicalLink.setAttribute('rel', 'canonical');
        // renderer.appendChild(this.document.head, this.canonicalLink);
        renderer.insertBefore(this.document.head, this.canonicalLink, baseRef);

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.queryParams = undefined;
                this.removeAllMetaTags();
            } else if (event instanceof NavigationEnd) {
                this.addCommonMetaTags();
                this.setCanonicalUrl();
            }
        });
        // this.route.queryParams.subscribe((params: Params) => {
        //     this.queryParams = params;
        //     console.log("Window queryParams", this.queryParams);
        //     this.addCommonMetaTags();
        // });
    }

    // This method is called from the FooterComponent class. It
    // appears HostListener doesn't work on services, so we rely on
    // the footer component to inform us when the window is resized.
    onResize(event: any) {
        this._width = event.target.innerWidth;
        this._height = event.target.innerHeight;

        console.log("Window size",
            event.target.innerWidth, event.target.innerHeight);

        this.windowSize$.emit([this._width, this._height]);

        let newDeviceType = this._width < 1194;

        if (this.wasLastDeviceTypeSmall !== undefined) {
            let oldDeviceType = this.wasLastDeviceTypeSmall;
            this.wasLastDeviceTypeSmall = newDeviceType;

            if (oldDeviceType != newDeviceType) {
                console.log("Emit deviceTypeChanged device is small",
                    newDeviceType);
                this.deviceTypeChanged$.emit(newDeviceType);
            }
        } else {
            this.wasLastDeviceTypeSmall = newDeviceType;
        }
    }

    public updateMetaTags() {
        this.removeAllMetaTags();
        this.addCommonMetaTags();
        this.setCanonicalUrl();
    }

    emitDeviceTypeChanged() {
        this.deviceTypeChanged$.emit(this.wasLastDeviceTypeSmall);
    }

    isSmallScreen() {
        // This must match the CSS definitions in
        // /src/app/skiresorts/ski-resort-router-outlet.component.scss,
        // where we have a different layout for devices below XL size.
        //
        // See also src/styles/_customizations.scss.
        return this.width < 1194;
    }

    scrollToTop() {
        window.scrollTo({ top: 0, /* behavior: 'smooth' */ });
    }

    private addCommonMetaTags() {
        // let paramsStr = new URLSearchParams(this.queryParams).toString();
        // if (paramsStr) {
        //     paramsStr = '?' + paramsStr;
        // }
        // let url = `https://${hostname}${this.router.url}`;
        let url = `https://${hostname}${this.router.url}`;
        console.log("Window adding og:url", url);

        // this.addMetaTag('og:url', `https://${hostname}${this.router.url}`);
        this.addMetaTag('og:url', url);
    }

    private setCanonicalUrl() {
        let canonicalUrl = location.origin + location.pathname;
        this.canonicalLink.setAttribute('href', canonicalUrl);
    }

    private removeAllMetaTags() {
        for (let tag of [
            'description', 'og:description',
            'article:published_time',
            'og:url', 'og:title', 'og:type',
            'og:image', 'og:image:url', 'og:image:width', 'og:image:height', 'og:image:type',
            'twitter:title', 'twitter:description',
            'twitter:image', 'twitter:card',
        ]) {
            this.removeTag(tag);
        }
    }

    public removeTag(name: string) {
        if (name == 'description') {
            this.meta.removeTag(`name='${name}'`);
        } else {
            this.meta.removeTag(`property='${name}'`);
        }
    }

    public addMetaTag(name: string, value: string) {
        let key = name == 'description' ? 'name' : 'property';
        let attributes: any = {};
        attributes[key] = name;
        attributes['content'] = value;
        let elt = this.meta.updateTag(attributes);
        if (!elt) {
            this.meta.addTag({ key: name, content: value });
        }
    }

    public updateMapboxStaticImageUrl(bounds: any[]): void {
        let sw: any = bounds[0];
        let ne: any = bounds[1];
        if (sw instanceof GPSPoint) {
            sw = sw.mapbox();
        }
        if (ne instanceof GPSPoint) {
            ne = ne.mapbox();
        }
        let url = `${environment.baseUrl}/staticmap3d/${sw[0]},${sw[1]},${ne[0]},${ne[1]}`;
        this.addMetaTag('og:image', url);
        this.addMetaTag('og:image:url', url);

        this.addMetaTag('twitter:image', url);
        this.addMetaTag('twitter:card', 'summary_large_image');
    }

    public updateMap2DStaticImageUrl(resort: Resort, urlizedMapName: string): void {
        let map: Map = resort.getMapByUrlizedName(urlizedMapName);
        if (!map) {
            console.log("Could not find map", urlizedMapName);
            return;
        }
        let url = `https://${hostname}/webapi2/map/${map.identifier}`;
        this.addMetaTag('og:image', url);
        this.addMetaTag('og:image:url', url);

        this.addMetaTag('twitter:image', url);
        this.addMetaTag('twitter:card', 'summary_large_image');
    }
}
