import { Injectable } from '@angular/core';
import {
    LngLat, GPSPoint, World, Continent, Country, State, ResortBrief,
    Resort, Lift, SkiRun, Restaurant
} from './datamodel';
import { SkiRunDifficulty } from './proto/common';
import { environment } from '../environments/environment';

const LOWERCASE = "abcdefghijklmnop";
const UPPERCASE = "ABCDEFGHIJKLMNOP";
const CHARACTERS = LOWERCASE + UPPERCASE;

const LOWER_COUNT = LOWERCASE.length;

const LOWER_MIN = LOWERCASE[0].charCodeAt(0);
const LOWER_MAX = LOWERCASE[LOWERCASE.length - 1].charCodeAt(0);
const UPPER_MIN = UPPERCASE[0].charCodeAt(0);
const UPPER_MAX = UPPERCASE[UPPERCASE.length - 1].charCodeAt(0);


export class SkiRunDifficultyIconName {
    difficulty: SkiRunDifficulty;
    iconName: string;

    constructor(difficulty: SkiRunDifficulty, iconName: string) {
        this.difficulty = difficulty;
        this.iconName = iconName;
    }
}


// This class is the parsed representation of a Jollyturns URL.
//
// URLs on the Jollyturns' website are of this type:
//
// /resorts
// /resorts/continent/north-america
// /resorts/country/united-states-of-america
// /resorts/country/united-states-of-america/california
//
// /resort/united-states-of-america/heavenly
// /resort/united-states-of-america/heavenly/lifts
// /resort/united-states-of-america/heavenly/skiruns-green
// /resort/united-states-of-america/heavenly/restaurants
// /resort/united-states-of-america/heavenly/lift/big-easy
// /resort/united-states-of-america/heavenly/skirun/patsys
// /resort/united-states-of-america/heavenly/restaurant/sky-deck
//
export class ParsedUrl {
    continentName?: string;
    continent?: Continent;

    countryName?: string;
    country?: Country;

    stateName?: string;
    state?: State;

    resortName?: string;
    resortBrief?: ResortBrief;
    resort?: Resort;

    liftName?: string;
    lift?: Lift;

    skirunName?: string;
    skirun?: SkiRun;

    restaurantName?: string;
    restaurant?: Restaurant;

    allResorts: boolean = false;
    resortsFromObject?: World | Continent | Country | State | undefined;
    resortBriefs?: ResortBrief[];
    bounds?: [GPSPoint, GPSPoint];

    lifts: boolean = false;
    skiruns: boolean = false;
    skirunsDifficulty?: SkiRunDifficulty;
    restaurants: boolean = false;

    mapsRequested: boolean = false;

    error?: string;

    // Whether the URL refers to a name that was used in a previous
    // primary version of the resort. An example would be Squaw Valley
    // which was renamed to Palisades Tahoe - Olympic Valley. We set
    // this flag if the url uses /resort/squaw-valley/, in which case
    // we want to redirect to the new name.
    urlUsesPreviousName: boolean = false;
}

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    private baseUrl = environment.baseUrl;

    private additionalUrlParts: string[] = [];

    constructor(
    ) { }

    isTouchEnabled() {
        return ('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            ((navigator as any).msMaxTouchPoints > 0);
    }

    setAdditionalUrlParts(parts: string[]) {
        this.additionalUrlParts = parts;
    }

    parsedUrlFrom(url: string,
        options: {
            world?: World,
            resort?: Resort
        }
    ): ParsedUrl {
        let parsed = new URL(url, this.baseUrl);
        let parsedUrl = new ParsedUrl();

        let components = parsed.pathname.split('/');

        // Keep in mind that `components` contains the empty string
        // before the first / in the URL

        if (options.resort && !options.world) {
            options.world = options.resort.world;
        }

        if (components.length < 2) {
            parsedUrl.error = `Bad URL`;
            return parsedUrl;
        }

        if (components[1] == 'resort') {
            if (components.length >= 3 && options.world) {
                let country = options.world.getCountryByUrlizedName(components[2]);
                if (country) {
                    parsedUrl.countryName = country.name;
                    parsedUrl.country = country;

                    parsedUrl.continent = country.continent;
                    parsedUrl.continentName = country.continent.name;

                    parsedUrl.bounds = country.bounds;
                } else {
                    parsedUrl.error = `No such country: ${components[2].unurlize()}`;
                    return parsedUrl;
                }
            }

            if (options.resort) {
                parsedUrl.state = options.resort.state;
                if (parsedUrl.state) {
                    parsedUrl.stateName = options.resort.state.name;
                    parsedUrl.bounds = parsedUrl.state.bounds;
                }
            }

            if (components.length >= 4) {
                // URL: /resort/united-states-of-america/heavenly
                if (parsedUrl.country) {
                    let resortBrief = parsedUrl.country.getResortByUrlizedName(components[3]);
                    if (!resortBrief) {
                        // Try again using one of the previous names
                        // of the resort.
                        resortBrief = parsedUrl.country.getResortByUrlizedPreviousName(components[3]);
                        parsedUrl.urlUsesPreviousName = true;
                    }

                    if (resortBrief) {
                        parsedUrl.resortBrief = resortBrief;
                        parsedUrl.resortName = resortBrief.name;
                        parsedUrl.bounds = resortBrief.bounds;
                    } else {
                        parsedUrl.error = `No such ski resort: ${components[3].unurlize()}`;
                        return parsedUrl;
                    }

                    if (options.resort) {
                        if (resortBrief.name == options.resort.name) {
                            parsedUrl.resort = options.resort;
                            parsedUrl.bounds = parsedUrl.resort.bounds;
                        } else {
                            parsedUrl.error = `Resort from URL (${components[3].unurlize()}) does not match resort passed as argument (${options.resort.name})`;
                            return parsedUrl;
                        }
                    }
                } else {
                    parsedUrl.error = `No such ski resort: ${components[3].unurlize()}`;
                    return parsedUrl;
                }
            }

            if (components.length >= 5) {
                // URL: /resort/united-states-of-america/heavenly/lifts
                let objectType = components[4];
                if (objectType == 'lifts') {
                    parsedUrl.lifts = true;
                } else if (objectType == 'restaurants') {
                    parsedUrl.restaurants = true;
                } else if (objectType.startsWith('skiruns-')) {
                    parsedUrl.skiruns = true;
                    let difficultyStr = objectType.substr('skiruns-'.length);
                    parsedUrl.skirunsDifficulty = this.urlPartToSkiRunDifficulty(difficultyStr);
                } else if (objectType == 'maps') {
                    parsedUrl.mapsRequested = true;
                } else if (components.length == 5) {
                    parsedUrl.error = `Bad ski resort request: ${objectType}`;
                    console.log("Bad ski resort request 1, components",
                        components, ", parsedUrl", parsedUrl);
                    return parsedUrl;
                }
            }

            if (components.length >= 6) {
                // URL: /resort/united-states-of-america/heavenly/lift/big-easy
                let objectType = components[4];
                let resortName = options.resort ? options.resort.name : components[3].unurlize();

                if (objectType == 'maps') {
                    parsedUrl.mapsRequested = true;
                    // Remove the 'maps' item from the array, so we
                    // can have the logic unaffected.
                    components.splice(4, 1);

                    // Assign the following URL component as the
                    // object type.
                    objectType = components[4];
                }

                if (objectType == 'skirun') {
                    parsedUrl.skirunName = components[5];
                    if (options.resort) {
                        parsedUrl.skirun = options.resort.getSkiRunByName(components[5]);
                        if (parsedUrl.skirun) {
                            parsedUrl.skirunName = parsedUrl.skirun.name;
                            parsedUrl.bounds = parsedUrl.skirun.bounds;
                        } else {
                            parsedUrl.error = `No ski run ${components[5].unurlize()} at ${resortName}`;
                            return parsedUrl;
                        }
                    }
                } else if (objectType == 'lift') {
                    parsedUrl.liftName = components[5];
                    if (options.resort) {
                        parsedUrl.lift = options.resort.getLiftByName(components[5]);
                        if (parsedUrl.lift) {
                            parsedUrl.liftName = parsedUrl.lift.name;
                            parsedUrl.bounds = parsedUrl.lift.bounds;
                        } else {
                            parsedUrl.error = `No lift ${components[5].unurlize()} at ${resortName}`;
                            return parsedUrl;
                        }
                    }
                } else if (objectType == 'restaurant') {
                    parsedUrl.restaurantName = components[5];
                    if (options.resort) {
                        parsedUrl.restaurant = options.resort.getRestaurantByName(
                            components[5]);

                        if (parsedUrl.restaurant) {
                            parsedUrl.restaurantName = parsedUrl.restaurant.name;
                            parsedUrl.bounds = parsedUrl.restaurant.bounds;
                        } else {
                            parsedUrl.error = `No restaurant ${components[5].unurlize()} at ${resortName}`;
                            return parsedUrl;
                        }
                    }
                } else if (this.additionalUrlParts.includes(objectType)) {
                    return parsedUrl;
                } else {
                    parsedUrl.error = `Bad ski resort request: ${objectType}`;
                    console.log("Bad ski resort request 2, components",
                        components, ", parsedUrl", parsedUrl);
                    return parsedUrl;
                }
            }

        } else if (components[1] == 'resorts') {
            // Let's handle URLs of the form:
            //
            // /resorts
            // /resorts/continent/north-america
            // /resorts/country/united-states-of-america
            // /resorts/country/united-states-of-america/california

            if (components.length >= 4) {
                // URL: /resorts/country/united-states-of-america
                // URL: /resorts/country/united-states-of-america/california
                if (components[2] == 'country') {
                    if (options.world) {
                        let country = options.world.getCountryByUrlizedName(components[3]);
                        if (country) {
                            parsedUrl.countryName = country.name;
                            parsedUrl.country = country;
                            parsedUrl.resortsFromObject = country;
                            parsedUrl.bounds = country.bounds;

                            parsedUrl.continent = country.continent;
                            parsedUrl.continentName = country.continent.name;
                            if (components.length >= 5) {
                                // URL: /resorts/country/united-states-of-america/california
                                let state = country.getStateByUrlizedName(components[4]);
                                if (state) {
                                    parsedUrl.state = state;
                                    parsedUrl.stateName = state.name;
                                    parsedUrl.resortsFromObject = state;
                                    parsedUrl.bounds = state.bounds;
                                } else {
                                    parsedUrl.error = `No such state, region or province: ${components[4].unurlize()}`;
                                    return parsedUrl;
                                }
                            }
                        } else {
                            parsedUrl.error = `No such country: ${components[3].unurlize()}`;
                            return parsedUrl;
                        }
                    } else {
                        parsedUrl.countryName = components[3].unurlize();
                        if (components.length >= 5) {
                            // URL: /resorts/country/united-states-of-america/california
                            parsedUrl.stateName = components[4].unurlize();
                        }
                    }
                } else if (components[2] == 'continent') {
                    // URL: /resorts/continent/north-america
                    if (options.world) {
                        let continent = options.world.getContinentByUrlizedName(components[3]);
                        if (continent) {
                            parsedUrl.continent = continent;
                            parsedUrl.continentName = continent.name;
                            parsedUrl.resortsFromObject = continent;
                            parsedUrl.bounds = continent.bounds;
                        } else {
                            parsedUrl.error = `No such continent: ${components[3].unurlize()}`;
                            return parsedUrl;
                        }
                    } else {
                        parsedUrl.continentName = components[3].unurlize();
                    }
                }
            } else if (components.length == 2) {
                parsedUrl.allResorts = true;
                parsedUrl.resortsFromObject = options.world;
                parsedUrl.bounds = options.world?.bounds;
            }
        }

        return parsedUrl;
    }

    round(num: number, decimals: number = 6) {
        let mult = Math.pow(10, decimals);
        return Math.round((num + Number.EPSILON) * mult) / mult;
    }

    // toBase64(bytes: Uint8Array): string {
    //     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    //     let base64 = new Array<string>(bytes.length * 3);
    //     let j = 0;
    //     let len = bytes.length;

    //     for (let i = 0; i < len; i += 3) {
    //         base64[j++] = chars[bytes[i] >> 2];
    //         base64[j++] = chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
    //         base64[j++] = chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
    //         base64[j++] = chars[bytes[i + 2] & 63];
    //     }
    //     if (len % 3 === 2) {
    //         base64[j++] = '=';
    //     } else if (len % 3 === 1) {
    //         base64[j++] = '=';
    //         base64[j++] = '=';
    //     }
    //     return base64.join('');
    // }

    scrollIntoViewIfNeeded(elt: any) {
        if (elt) {
            console.log("Scroll into view", elt);
            let rect = elt.getBoundingClientRect();
            if (rect.top > window.innerHeight || rect.top < 60 ||
                rect.bottom > window.innerHeight || rect.bottom < 0) {
                elt.scrollIntoView(false);
            }
        }
    }

    urlPartForSummary(): string {
        return '';
    }

    urlPartToSkiRunDifficulty(value: string): SkiRunDifficulty {
        switch (value.toLowerCase()) {
            case 'green':
                return SkiRunDifficulty.GREEN;
            case 'blue':
                return SkiRunDifficulty.BLUE;
            case 'black':
                return SkiRunDifficulty.BLACK;
            case 'double-black':
                return SkiRunDifficulty.DOUBLE_BLACK;
            case 'terrain-park':
                return SkiRunDifficulty.TERRAIN_PARK;
            case 'triple-black':
                return SkiRunDifficulty.TRIPLE_BLACK;
            case 'red':
                return SkiRunDifficulty.RED;
            case 'orange':
                return SkiRunDifficulty.ORANGE;
            case 'yellow':
                return SkiRunDifficulty.YELLOW;
            default:
                return SkiRunDifficulty.UNSPECIFIED$;
        }
    }

    urlPartForSkiRunDifficulty(difficulty: SkiRunDifficulty) {
        switch (difficulty) {
            case SkiRunDifficulty.GREEN:
                return 'skiruns-green';
            case SkiRunDifficulty.BLUE:
                return 'skiruns-blue';
            case SkiRunDifficulty.BLACK:
                return 'skiruns-black';
            case SkiRunDifficulty.DOUBLE_BLACK:
                return 'skiruns-double-black';
            case SkiRunDifficulty.TERRAIN_PARK:
                return 'skiruns-terrain-park';
            case SkiRunDifficulty.TRIPLE_BLACK:
                return 'skiruns-triple-black';
            case SkiRunDifficulty.RED:
                return 'skiruns-red';
            case SkiRunDifficulty.ORANGE:
                return 'skiruns-orange';
            case SkiRunDifficulty.YELLOW:
                return 'skiruns-yellow';
            default:
                return 'skiruns';
        }
    }

    skiRunDifficultyToName(difficulty: SkiRunDifficulty): string {
        switch (difficulty) {
            case SkiRunDifficulty.GREEN:
                return 'Green';
            case SkiRunDifficulty.BLUE:
                return 'Blue';
            case SkiRunDifficulty.BLACK:
                return 'Black';
            case SkiRunDifficulty.DOUBLE_BLACK:
                return 'Double black';
            case SkiRunDifficulty.TERRAIN_PARK:
                return 'Terrain park';
            case SkiRunDifficulty.TRIPLE_BLACK:
                return 'Triple black';
            case SkiRunDifficulty.RED:
                return 'Red';
            case SkiRunDifficulty.ORANGE:
                return 'Orange';
            case SkiRunDifficulty.YELLOW:
                return 'Yellow';
            case SkiRunDifficulty.UNSPECIFIED$:
                return 'Unspecified';
        }
    }

    colorForSkiRunDifficulty(difficulty: SkiRunDifficulty) {
        switch (difficulty) {
            case SkiRunDifficulty.GREEN:
                return '#32cd32';
            case SkiRunDifficulty.BLUE:
                return '#037BFF';
            // return '#0D86D0';
            // return '#08728E';
            case SkiRunDifficulty.BLACK:
                return '#111111';
            case SkiRunDifficulty.DOUBLE_BLACK:
                return '#000000';
            case SkiRunDifficulty.TERRAIN_PARK:
                return '#9400d3'; // dark violet
            case SkiRunDifficulty.TRIPLE_BLACK:
                return '#000000';
            case SkiRunDifficulty.RED:
                return '#ff0000';
            case SkiRunDifficulty.ORANGE:
                return '#ffa500';
            case SkiRunDifficulty.YELLOW:
                return '#FFB914';
            default:
                return '#1e90ff'; //dodger blue
        }
    }

    colorForHighlighedSkiRunDifficulty(difficulty: SkiRunDifficulty) {
        switch (difficulty) {
            case SkiRunDifficulty.GREEN:
                // return '#32cd32'; // lime green
                return '#74F78D';
            case SkiRunDifficulty.BLUE:
                // return '#138DD3';
                return '#93C1F8';
            case SkiRunDifficulty.BLACK:
                // return '#111111';
                // return '#253200';
                return '#FF5B5B';
            case SkiRunDifficulty.DOUBLE_BLACK:
                // return '#000000';
                // return '#253200';
                return '#FF5B5B';
            case SkiRunDifficulty.TERRAIN_PARK:
                // return '#9400d3'; // dark violet
                return '#FF4147';
            case SkiRunDifficulty.TRIPLE_BLACK:
                // return '#000000';
                // return '#253200';
                return '#FF5B5B';
            case SkiRunDifficulty.RED:
                // return '#ff0000'; // red
                // return '#FF4147';
                // return '#EBB8DF';
                // return '#7E1B67';
                return '#F3CCCF';
            case SkiRunDifficulty.ORANGE:
                return '#ffa500'; // orange
            case SkiRunDifficulty.YELLOW:
                // return '#ffff00'; //yellow
                // return '#FFB914';
                return '#ffff00'; //yellow
            default:
                return '#1e90ff'; //dodger blue
        }
    }

    public layerNameForSkiRunDifficulty(difficulty: SkiRunDifficulty): string {
        switch (difficulty) {
            case SkiRunDifficulty.GREEN: return 'green';
            case SkiRunDifficulty.BLUE: return 'blue';
            case SkiRunDifficulty.BLACK: return 'black';
            case SkiRunDifficulty.DOUBLE_BLACK: return 'doubleblack';
            case SkiRunDifficulty.TERRAIN_PARK: return 'terrainpark';
            case SkiRunDifficulty.TRIPLE_BLACK: return 'tripleblack';
            case SkiRunDifficulty.RED: return 'red';
            case SkiRunDifficulty.ORANGE: return 'orange';
            case SkiRunDifficulty.YELLOW: return 'yellow';
            default: return 'unknown';
        }
    }

    public nameForSkiRunDifficulty(difficulty: SkiRunDifficulty): string {
        switch (difficulty) {
            case SkiRunDifficulty.GREEN: return 'green';
            case SkiRunDifficulty.BLUE: return 'blue';
            case SkiRunDifficulty.BLACK: return 'black';
            case SkiRunDifficulty.DOUBLE_BLACK: return 'double black';
            case SkiRunDifficulty.TERRAIN_PARK: return 'terrain park';
            case SkiRunDifficulty.TRIPLE_BLACK: return 'triple black';
            case SkiRunDifficulty.RED: return 'red';
            case SkiRunDifficulty.ORANGE: return 'orange';
            case SkiRunDifficulty.YELLOW: return 'yellow';
            default: return 'unknown';
        }
    }

    skiRunDifficultiesAndIconNamesForObject(object: Continent | Country | ResortBrief)
        : SkiRunDifficultyIconName[] {
        let difficulties: SkiRunDifficultyIconName[] = [];

        if (object instanceof ResortBrief) {
            let resort: ResortBrief = object as ResortBrief;
            for (let difficulty of resort.difficulties) {
                let difficultyIcon = new SkiRunDifficultyIconName(
                    difficulty,
                    this.svgNameForSkiRunDifficulty(resort, difficulty));
                difficulties.push(difficultyIcon);
            }
            return difficulties;
        }

        let country_name = (object instanceof Country
            ? object.name
            : undefined);
        let continent_name = (object instanceof Continent
            ? object.name
            : (object as Country).continent.name);

        if (continent_name == "North America") {
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.GREEN,
                    'difficulty-green-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-square'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-diamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.DOUBLE_BLACK,
                    'difficulty-doublediamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TRIPLE_BLACK,
                    'difficulty-triple-diamond'),
            ];
        }
        else if (country_name == "France") {
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.GREEN,
                    'difficulty-green-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.RED,
                    'difficulty-red-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.YELLOW,
                    'difficulty-yellow-diamond'),
            ];
        }
        else if (country_name == "Austria" || country_name == "Switzerland") {
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.RED,
                    'difficulty-red-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.ORANGE,
                    'difficulty-orange-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.YELLOW,
                    'difficulty-yellow-diamond'),
            ];
        }
        else if (country_name == "Norway" || country_name == "Sweden") {
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.GREEN,
                    'difficulty-green-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.RED,
                    'difficulty-red-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.DOUBLE_BLACK,
                    'difficulty-doublediamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.ORANGE,
                    'difficulty-orange-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.YELLOW,
                    'difficulty-yellow-diamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.DOUBLE_BLACK,
                    'difficulty-doublediamond'),
            ];
        }
        else if (country_name == "Turkey" || country_name == "Lebanon") {
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.GREEN,
                    'difficulty-green-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.RED,
                    'difficulty-red-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.ORANGE,
                    'difficulty-orange-circle'),
            ];
        }
        else if (country_name == "South Korea") {
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.GREEN,
                    'difficulty-green-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.RED,
                    'difficulty-red-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.DOUBLE_BLACK,
                    'difficulty-doublediamond'),
            ];
        }
        else if (continent_name == "Europe") {
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.GREEN,
                    'difficulty-green-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.RED,
                    'difficulty-red-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.DOUBLE_BLACK,
                    'difficulty-doublediamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.ORANGE,
                    'difficulty-orange-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.YELLOW,
                    'difficulty-yellow-diamond'),
            ];
        }
        else if (continent_name == "Australia" || continent_name == "Zealandia") {
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.GREEN,
                    'difficulty-green-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-square'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.RED,
                    'difficulty-red-square'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-diamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.DOUBLE_BLACK,
                    'difficulty-doublediamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TRIPLE_BLACK,
                    'difficulty-triple-diamond'),
            ];
        }
        else if (country_name == "Japan") {
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.GREEN,
                    'difficulty-green-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-square'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.RED,
                    'difficulty-red-square'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-diamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.DOUBLE_BLACK,
                    'difficulty-doublediamond'),
            ];
        } else {
            // We have an unknown country or continent. Provide some
            // defaults here.
            difficulties = [
                new SkiRunDifficultyIconName(SkiRunDifficulty.GREEN,
                    'difficulty-green-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLUE,
                    'difficulty-blue-square'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TERRAIN_PARK,
                    'difficulty-terrain-park'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.BLACK,
                    'difficulty-black-diamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.DOUBLE_BLACK,
                    'difficulty-doublediamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.TRIPLE_BLACK,
                    'difficulty-triple-diamond'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.RED,
                    'difficulty-red-square'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.ORANGE,
                    'difficulty-orange-circle'),
                new SkiRunDifficultyIconName(SkiRunDifficulty.YELLOW,
                    'difficulty-yellow-diamond'),
            ];
        }
        return difficulties;
    }

    // Must match Datamodel+JTAdditions.mm
    // + (NSString*)imageNameForDifficulty:(int)difficulty resort:(Resort*)resort
    svgNameForSkiRunDifficulty(
        resort: Resort | ResortBrief,
        difficulty: SkiRunDifficulty
    ) {
        let country: Country = resort.country;
        let continent: Continent = country.continent;
        let iconName: string;

        if (continent.name == "North America") {
            switch (difficulty) {
                case SkiRunDifficulty.GREEN:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-square';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-diamond';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.DOUBLE_BLACK:
                    iconName = 'difficulty-doublediamond';
                    break;
                case SkiRunDifficulty.TRIPLE_BLACK:
                    iconName = 'difficulty-triple-diamond';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
            }
        }
        else if (country.name == "France") {
            switch (difficulty) {
                case SkiRunDifficulty.GREEN:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-circle';
                    break;
                case SkiRunDifficulty.RED:
                    iconName = 'difficulty-red-circle';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-circle';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.YELLOW:
                    iconName = 'difficulty-yellow-diamond';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
            }
        }
        else if (country.name in ["Austria", "Switzerland"]) {
            switch (difficulty) {
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-circle';
                    break;
                case SkiRunDifficulty.RED:
                    iconName = 'difficulty-red-circle';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-circle';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.ORANGE:
                    iconName = 'difficulty-orange-circle';
                    break;
                case SkiRunDifficulty.YELLOW:
                    iconName = 'difficulty-yellow-diamond';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
            }
        }
        else if (country.name == "Norway" || country.name == "Sweden") {
            switch (difficulty) {
                case SkiRunDifficulty.GREEN:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-circle';
                    break;
                case SkiRunDifficulty.RED:
                    iconName = 'difficulty-red-circle';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-circle';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.DOUBLE_BLACK:
                    iconName = 'difficulty-doublediamond';
                    break;
                case SkiRunDifficulty.ORANGE:
                    iconName = 'difficulty-orange-circle';
                    break;
                case SkiRunDifficulty.YELLOW:
                    iconName = 'difficulty-yellow-diamond';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.DOUBLE_BLACK:
                    iconName = 'difficulty-doublediamond';
                    break;
            }
        }
        else if (country.name in ["Turkey", "Lebanon"]) {
            switch (difficulty) {
                case SkiRunDifficulty.GREEN:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-circle';
                    break;
                case SkiRunDifficulty.RED:
                    iconName = 'difficulty-red-circle';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-circle';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.ORANGE:
                    iconName = 'difficulty-orange-circle';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
            }
        }
        else if (country.name == "South Korea") {
            switch (difficulty) {
                case SkiRunDifficulty.GREEN:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-circle';
                    break;
                case SkiRunDifficulty.RED:
                    iconName = 'difficulty-red-circle';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-circle';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.DOUBLE_BLACK:
                    iconName = 'difficulty-doublediamond';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
            }
        }
        else if (continent.name == "Europe") {
            switch (difficulty) {
                case SkiRunDifficulty.GREEN:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-circle';
                    break;
                case SkiRunDifficulty.RED:
                    iconName = 'difficulty-red-circle';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-circle';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.DOUBLE_BLACK:
                    iconName = 'difficulty-doublediamond';
                    break;
                case SkiRunDifficulty.ORANGE:
                    iconName = 'difficulty-orange-circle';
                    break;
                case SkiRunDifficulty.YELLOW:
                    iconName = 'difficulty-yellow-diamond';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
            }
        }
        else if (continent.name in ["Australia", "Zealandia"]) {
            switch (difficulty) {
                case SkiRunDifficulty.GREEN:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-square';
                    break;
                case SkiRunDifficulty.RED:
                    iconName = 'difficulty-red-square';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-diamond';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.DOUBLE_BLACK:
                    iconName = 'difficulty-doublediamond';
                    break;
                case SkiRunDifficulty.TRIPLE_BLACK:
                    iconName = 'difficulty-triple-diamond';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
            }
        }
        else if (country.name == "Japan") {
            switch (difficulty) {
                case SkiRunDifficulty.GREEN:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-square';
                    break;
                case SkiRunDifficulty.RED:
                    iconName = 'difficulty-red-square';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-diamond';
                    break;
                case SkiRunDifficulty.DOUBLE_BLACK:
                    iconName = 'difficulty-doublediamond';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
            }
        } else {
            // We have an unknown country or continent. Provide some
            // defaults here.
            switch (difficulty) {
                case SkiRunDifficulty.GREEN:
                    iconName = 'difficulty-green-circle';
                    break;
                case SkiRunDifficulty.BLUE:
                    iconName = 'difficulty-blue-square';
                    break;
                case SkiRunDifficulty.BLACK:
                    iconName = 'difficulty-black-diamond';
                    break;
                case SkiRunDifficulty.TERRAIN_PARK:
                    iconName = 'difficulty-terrain-park';
                    break;
                case SkiRunDifficulty.DOUBLE_BLACK:
                    iconName = 'difficulty-doublediamond';
                    break;
                case SkiRunDifficulty.TRIPLE_BLACK:
                    iconName = 'difficulty-triple-diamond';
                    break;
                case SkiRunDifficulty.RED:
                    iconName = 'difficulty-red-square';
                    break;
                case SkiRunDifficulty.ORANGE:
                    iconName = 'difficulty-orange-circle';
                    break;
                case SkiRunDifficulty.YELLOW:
                    iconName = 'difficulty-yellow-diamond';
                    break;
                default:
                    iconName = 'difficulty-green-circle';
                    break;
            }
        }

        return iconName;
    }

    iconForSkiRunDifficulty(
        resort: Resort,
        difficulty: SkiRunDifficulty,
        selected: boolean
    ) {
        let name = this.svgNameForSkiRunDifficulty(resort, difficulty);
        if (!selected) {
            name += '-unselected';
        }
        return name.replace(/-/g, '_');
    }

    skiRunDifficultyToQueryParam(difficulty: SkiRunDifficulty) {
        switch (difficulty) {
            case SkiRunDifficulty.GREEN:
                return 'vg';
            case SkiRunDifficulty.BLUE:
                return 'vb';
            case SkiRunDifficulty.BLACK:
                return 'vB';
            case SkiRunDifficulty.TERRAIN_PARK:
                return 'vt';
            case SkiRunDifficulty.DOUBLE_BLACK:
                return 'vBB';
            case SkiRunDifficulty.TRIPLE_BLACK:
                return 'vBBB';
            case SkiRunDifficulty.RED:
                return 'vr';
            case SkiRunDifficulty.ORANGE:
                return 'vo';
            case SkiRunDifficulty.YELLOW:
                return 'vy';
            default:
                return '';
        }
    }

    compress(value: string): string {
        let compressed = "";
        for (let index = 0; index != value.length; index++) {
            let count = 1;
            while (index < value.length - 1 &&
                value[index] == value[index + 1]) {
                count++;
                index++;
            }
            compressed += value[index]
            if (count > 1) {
                compressed += count;
            }
        }
        return compressed;
    }

    decompress(value: string): string | undefined {
        let decompressed = "";
        let ch: string | undefined = undefined;
        for (let index = 0; index < value.length;) {
            if (isNaN(parseInt(value[index], 10))) {
                ch = value[index];
                decompressed += ch;
                index++;
                continue;
            }

            let num = "";
            while (index < value.length && !isNaN(parseInt(value[index], 10))) {
                num += value[index];
                index++;
            }

            let repeated = parseInt(num, 10);
            if (ch === undefined) {
                return undefined;
            } else {
                decompressed += (ch as string).repeat(repeated - 1);
            }

        }
        return decompressed;
    }

    encodeTileXYZ(xn: number, yn: number, zn: number): string | undefined {
        if (zn > 29 || xn >= 2 ** zn || yn >= 2 ** zn) {
            console.error("Invalid constraints x, y, z", xn, yn, zn);
            return undefined;
        }

        let x: bigint = BigInt(xn);
        let y: bigint = BigInt(yn);
        let z: bigint = BigInt(zn);

        // console.log("encodeTileXYZ x", x, "y", y, "z", z);
        let num: bigint = (x << BigInt(34)) | (y << BigInt(5)) | z;
        let value = "";
        let mask = BigInt(0xf8) << (BigInt(64 - 8));
        let shift = BigInt(64 - 5);
        // console.log("Encoding number", num);
        for (let index = 0; index < 13; index++) {
            let c = shift > BigInt(0) ? ((num & mask) >> shift) : (num & mask);
            // console.log("Looking at character with pos", c);
            value += CHARACTERS[c as any];
            mask = mask >> BigInt(5);
            shift -= BigInt(5);
        }
        // console.log("Uncompressed value", value);
        return this.compress(value);
    }

    decodeTileXYZ(input: string): [number, number, number] | undefined {
        let decompressed = this.decompress(input);
        if (decompressed === undefined) {
            return undefined;
        }

        let value: bigint = BigInt(0);
        let shift: bigint = BigInt(64 - 5);
        for (let index = 0; index < 13; index++) {
            let ch: string = decompressed[index];
            let chcode: number = ch.charCodeAt(0);
            let num: bigint;
            if (LOWER_MIN <= chcode && chcode <= LOWER_MAX) {
                num = BigInt(chcode - LOWER_MIN);
            } else if (UPPER_MIN <= chcode && chcode <= UPPER_MAX) {
                num = BigInt(chcode - UPPER_MIN + LOWER_COUNT);
            } else {
                console.log("Found invalid character", ch, "in string", input);
                return undefined;
            }

            value |= shift > 0 ? num << shift : num;
            shift -= BigInt(5);
        }

        let x: bigint = value >> BigInt(34);
        let y: bigint = (value >> BigInt(5)) & BigInt(0x1fffffff);
        let z: bigint = value & BigInt(0x1F);

        return [Number(x), Number(y), Number(z)];
    }
}
