import {
    Component, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef
} from '@angular/core';

import { Subscription } from 'rxjs';

import { ProgressBarService } from './progress-bar.service';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    private _show: boolean = false;
    public get show() { return this._show; }
    public set show(value: boolean) {
        this._show = value;
        this._percentage = 0;
        this._percentage$.emit(0);
    }

    private _percentage: number = 0;
    public get percentage() { return this._percentage; }
    public set percentage(value: number) {
        this._percentage = value;
        this._percentage$.emit(value);
    }

    private _percentage$: EventEmitter<number> = new EventEmitter();
    public get percentage$() { return this._percentage$; }

    constructor(
        public changeDetector: ChangeDetectorRef,
        private progressService: ProgressBarService,
    ) {
        this.subscription.add(
            this.progressService.show$.subscribe((value: boolean) => {
                this.show = value;
                // console.log("ProgressBarComponent show:", value);
                this.changeDetector.detectChanges();
            })
        );
        this.subscription.add(
            this.progressService.percentage$.subscribe((value: number) => {
                this.percentage = value;
                // console.log("ProgressBarComponent percentage:", value);
                this.changeDetector.detectChanges();
            })
        );
    }

    ngOnInit(): void {
        this.show = this.progressService.show;
        this.percentage = this.progressService.percentage;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
