import { Injectable, EventEmitter } from '@angular/core';
import {
    HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders,
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { Observable, throwError, Subscription, pipe, } from 'rxjs';
import { map, concatMap, filter, share } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class XSRFService {
    private _xsrf?: string;
    public get xsrf(): string | undefined { return this._xsrf; }
    public set xsrf(value: string | undefined) {
        this._xsrf = value;
    }

    private _xsrfObservable: Observable<any>;

    private _xsrfInFlight = false;

    private _countryCode?: string;
    public get countryCode() {
        return this._countryCode;
    }

    private _country$: EventEmitter<string> = new EventEmitter();
    public get country$() { return this._country$; }

    constructor(
        private http: HttpClient,
        private cookie: CookieService,
    ) {
        console.log("XSRFService created");

        this.xsrf = this.cookie.get('_xsrf');
        console.log("Got XSRF cookie", this.xsrf);

        this._countryCode = this.cookie.get('cc')
        console.log("Got country code", this._countryCode);

        if (this._countryCode) {
            setTimeout(() => this._country$.emit(this._countryCode), 1);
        }

        this._xsrfObservable = this.http.get<string>(
            environment.baseUrl + '/ping'
        ).pipe(share());
    }

    public maybeFetchXsrfThenInvoke(methodObs: Observable<any>) {
        if (this._xsrf) {
            return methodObs;
        } else {
            return this._xsrfObservable.pipe(map((result) => {
                // console.log("Got ping response", result);
                this.extractXSRFCookie(result);
            })).pipe(concatMap(() => methodObs));
        }
    }

    private extractXSRFCookie(result: any) {
        if (result) {
            this._xsrf = result["xsfr_token"];
            // console.log("Got XSRF token", this._xsrf);

            this._countryCode = result['cc'];
            console.log("Got country code", this._countryCode);
            this._country$.emit(this._countryCode);
        }

        // console.log("Got cookie", this._xsrf);
    }
}


@Injectable({
    providedIn: 'root'
})
export class ClientXSRFTokenInterceptor implements HttpInterceptor {

    constructor(private xsrfService: XSRFService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log("XSRF service", this.xsrfService);
        let xsrf: string | undefined = this.xsrfService.xsrf;
        // console.log("Intercepting HTTP request xsrf", xsrf,
        //     "for URL", req.url);
        // console.log("Intercepting HTTP request", req);

        if (xsrf) {
            const authReq = req.clone({
                headers: req.headers.set('X-XSRFToken', xsrf),
                // params: req.params.append('_xsrf', xsrf),

            });
            return next.handle(authReq);
        } else {
            return next.handle(req);
        }
    }
}

export const httpInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ClientXSRFTokenInterceptor,
        multi: true
    },
];
