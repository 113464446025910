import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-loginbutton',
    templateUrl: './loginbutton.component.html',
    styleUrls: ['./loginbutton.component.scss']
})
export class LoginButtonComponent implements OnInit {

    @Input() type?: string;
    @Output() action = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit(): void {
    }

    invokeAction() {
        if (this.action) {
            this.action.emit('pressed');
        }
    }
}
