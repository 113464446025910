<header>
  <app-navbar [showExtraLinks]="true"></app-navbar>
</header>

<div class="content space-around">
  <div class="container-fluid">
    <h4>Price calculator</h4>


    <div class="row">
      <label for="quantity">Enter the number of map views per month in
        1000s:
      </label>
    </div>

    <div class="row">
      <input id="quantity"
             type="text"
             class="form-control col-xs-12 col-lg-2"
             [(ngModel)]="quantity"
             (ngModelChange)="quantityChanged()"
             style="display: inline;"
             >
      <span class="col-xs-12 col-lg-6">{{cost | currency}} per month</span>
    </div>
    <br/>
    <div class="row">
      Total number of map views: {{quantity * 1000 | number}}.
    </div>

  </div>
</div>

<footer>
  <app-footer></app-footer>
</footer>
