// @generated by protobuf-ts 1.0.13 with parameters long_type_string
// @generated from protobuf file "requests.proto" (syntax proto2)
// tslint:disable
import { BinaryWriteOptions } from "@protobuf-ts/runtime";
import { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import { BinaryReadOptions } from "@protobuf-ts/runtime";
import { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType as MessageType$ } from "@protobuf-ts/runtime";
import { WeatherStationObservation } from "./common";
import { Resort } from "./common";
import { SkiRunDifficulty } from "./common";
import { ResortSnow } from "./common";
import { GPSPoint } from "./common";
import { GPSPointArray } from "./common";
import { MessageType } from "./common";
/**
 * An empty message type passed as argument in or returned as value
 * from an RPC method
 *
 * @generated from protobuf message Empty
 */
export interface Empty {
    /**
     * @generated from protobuf field: optional MessageType type = 1;
     */
    type?: MessageType;
}
/**
 * END-WEB
 * START-WEB
 *
 * @generated from protobuf message AllResorts2
 */
export interface AllResorts2 {
    /**
     * @generated from protobuf field: optional MessageType type = 1;
     */
    type?: MessageType;
    /**
     * @generated from protobuf field: optional int32 version = 2;
     */
    version?: number;
    /**
     * @generated from protobuf field: repeated AllResorts2.Geometry continents = 4;
     */
    continents: AllResorts2_Geometry[];
    /**
     * @generated from protobuf field: repeated AllResorts2.Geometry countries = 5;
     */
    countries: AllResorts2_Geometry[];
    /**
     * @generated from protobuf field: repeated AllResorts2.Resort resorts = 3;
     */
    resorts: AllResorts2_Resort[];
}
/**
 * @generated from protobuf message AllResorts2.Map
 */
export interface AllResorts2_Map {
    /**
     * @generated from protobuf field: optional int64 image_identifier = 3;
     */
    imageIdentifier?: string;
}
/**
 * @generated from protobuf message AllResorts2.Geometry
 */
export interface AllResorts2_Geometry {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated GPSPointArray polygon = 2;
     */
    polygon: GPSPointArray[];
}
/**
 * @generated from protobuf message AllResorts2.Resort
 */
export interface AllResorts2_Resort {
    /**
     * @generated from protobuf field: int32 resort_uid = 8;
     */
    resortUid: number;
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: optional string name_ja = 13;
     */
    nameJa?: string;
    /**
     * @generated from protobuf field: optional string name_cyrillic = 14;
     */
    nameCyrillic?: string;
    /**
     * @generated from protobuf field: string continent = 3;
     */
    continent: string;
    /**
     * @generated from protobuf field: repeated string countries = 4;
     */
    countries: string[];
    /**
     * @generated from protobuf field: repeated string states = 5;
     */
    states: string[];
    /**
     * @generated from protobuf field: optional GPSPoint centroid = 9;
     */
    centroid?: GPSPoint;
    /**
     * @generated from protobuf field: optional int32 radiusE2 = 11;
     */
    radiusE2?: number;
    /**
     * @generated from protobuf field: optional GPSPoint northeast = 23;
     */
    northeast?: GPSPoint;
    /**
     * @generated from protobuf field: optional GPSPoint southwest = 24;
     */
    southwest?: GPSPoint;
    /**
     * @generated from protobuf field: optional string timezone = 12;
     */
    timezone?: string;
    /**
     * @generated from protobuf field: optional int32 number_of_lifts = 15;
     */
    numberOfLifts?: number;
    /**
     * @generated from protobuf field: optional int32 base_elevationE2 = 16;
     */
    baseElevationE2?: number;
    /**
     * @generated from protobuf field: optional int32 top_elevationE2 = 17;
     */
    topElevationE2?: number;
    /**
     * @generated from protobuf field: optional int32 most_vertical_for_a_liftE2 = 18;
     */
    mostVerticalForALiftE2?: number;
    /**
     * @generated from protobuf field: optional int32 number_of_skiruns = 19;
     */
    numberOfSkiruns?: number;
    /**
     * @generated from protobuf field: optional int32 total_length_of_skirunsE2 = 20;
     */
    totalLengthOfSkirunsE2?: number;
    /**
     * @generated from protobuf field: optional int32 length_of_longest_skirunE2 = 21;
     */
    lengthOfLongestSkirunE2?: number;
    /**
     * @generated from protobuf field: optional int32 most_vertical_for_a_skirunE2 = 22;
     */
    mostVerticalForASkirunE2?: number;
    /**
     * @generated from protobuf field: optional bool season_is_open = 25;
     */
    seasonIsOpen?: boolean;
    /**
     * @generated from protobuf field: optional string season_start = 26;
     */
    seasonStart?: string;
    /**
     * @generated from protobuf field: optional string season_end = 27;
     */
    seasonEnd?: string;
    /**
     * @generated from protobuf field: optional ResortSnow snow = 28;
     */
    snow?: ResortSnow;
    /**
     * @generated from protobuf field: repeated AllResorts2.Map maps_obsolete = 7;
     */
    mapsObsolete: AllResorts2_Map[];
    /**
     * Previous names for this resort
     *
     * @generated from protobuf field: repeated string previous_names = 29;
     */
    previousNames: string[];
    /**
     * @generated from protobuf field: repeated SkiRunDifficulty difficulties = 30;
     */
    difficulties: SkiRunDifficulty[];
    /**
     * @generated from protobuf field: optional GPSPointArray boundary = 31;
     */
    boundary?: GPSPointArray;
}
/**
 * @generated from protobuf message AllResortBoundaries
 */
export interface AllResortBoundaries {
    /**
     * @generated from protobuf field: optional MessageType type = 1;
     */
    type?: MessageType;
    /**
     * @generated from protobuf field: optional int32 version = 2;
     */
    version?: number;
    /**
     * @generated from protobuf field: repeated AllResortBoundaries.Geometry continents = 3;
     */
    continents: AllResortBoundaries_Geometry[];
    /**
     * @generated from protobuf field: repeated AllResortBoundaries.Geometry countries = 4;
     */
    countries: AllResortBoundaries_Geometry[];
    /**
     * @generated from protobuf field: repeated AllResortBoundaries.Resort resorts = 5;
     */
    resorts: AllResortBoundaries_Resort[];
}
/**
 * @generated from protobuf message AllResortBoundaries.Geometry
 */
export interface AllResortBoundaries_Geometry {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated GPSPointArray polygon = 2;
     */
    polygon: GPSPointArray[];
}
/**
 * @generated from protobuf message AllResortBoundaries.Resort
 */
export interface AllResortBoundaries_Resort {
    /**
     * @generated from protobuf field: int32 resort_uid = 1;
     */
    resortUid: number;
    /**
     * @generated from protobuf field: repeated SkiRunDifficulty difficulties = 2;
     */
    difficulties: SkiRunDifficulty[];
    /**
     * @generated from protobuf field: GPSPointArray boundary = 3;
     */
    boundary?: GPSPointArray;
}
/**
 * @generated from protobuf message UserRequest
 */
export interface UserRequest {
    /**
     * @generated from protobuf field: optional MessageType type = 1;
     */
    type?: MessageType;
    /**
     * @generated from protobuf field: optional int32 version = 2;
     */
    version?: number;
    /**
     * @generated from protobuf field: optional string distance_units = 3;
     */
    distanceUnits?: string;
    /**
     * @generated from protobuf field: optional string elevation_units = 4;
     */
    elevationUnits?: string;
    /**
     * @generated from protobuf field: optional string temperature_units = 5;
     */
    temperatureUnits?: string;
    /**
     * @generated from protobuf field: optional bytes image = 6;
     */
    image?: Uint8Array;
    /**
     * @generated from protobuf field: optional string mime_type = 7;
     */
    mimeType?: string;
}
/**
 * @generated from protobuf message UserResponse
 */
export interface UserResponse {
    /**
     * @generated from protobuf field: optional int32 version = 1;
     */
    version?: number;
    /**
     * @generated from protobuf field: optional string error = 7;
     */
    error?: string;
    /**
     * @generated from protobuf field: optional string distance_units = 2;
     */
    distanceUnits?: string;
    /**
     * @generated from protobuf field: optional string elevation_units = 3;
     */
    elevationUnits?: string;
    /**
     * @generated from protobuf field: optional string temperature_units = 4;
     */
    temperatureUnits?: string;
    /**
     * @generated from protobuf field: repeated UserResponse.Route extra_routes = 5;
     */
    extraRoutes: UserResponse_Route[];
    /**
     * @generated from protobuf field: optional string extra_menu_title = 6;
     */
    extraMenuTitle?: string;
    /**
     * Base64 encoded images
     *
     * @generated from protobuf field: optional string small_image = 8;
     */
    smallImage?: string;
    /**
     * @generated from protobuf field: optional string large_image = 9;
     */
    largeImage?: string;
    /**
     * Credits
     *
     * @generated from protobuf field: optional int32 num_credits_available = 10;
     */
    numCreditsAvailable?: number;
    /**
     * @generated from protobuf field: repeated int32 resort_uid = 11;
     */
    resortUid: number[];
}
/**
 * Extra routes used in the web app. These are set by the backend
 * based on the user's entitlements.
 *
 * @generated from protobuf message UserResponse.Route
 */
export interface UserResponse_Route {
    /**
     * @generated from protobuf field: string module_name = 3;
     */
    moduleName: string;
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
}
/**
 * @generated from protobuf message GetResortsResponse2
 */
export interface GetResortsResponse2 {
    /**
     * @generated from protobuf field: optional int32 version = 1;
     */
    version?: number;
    /**
     * An error message is present if things go wrong.
     *
     * @generated from protobuf field: optional string error = 3;
     */
    error?: string;
    /**
     * @generated from protobuf field: optional Resort resort = 4;
     */
    resort?: Resort;
    /**
     * @generated from protobuf field: optional bool has_heatmap = 10;
     */
    hasHeatmap?: boolean;
    /**
     * @generated from protobuf field: optional ResortSnow snow = 6;
     */
    snow?: ResortSnow;
    /**
     * @generated from protobuf field: repeated WeatherStationObservation observations = 5;
     */
    observations: WeatherStationObservation[];
}
/**
 * @generated from protobuf message CheckoutSessionRequest
 */
export interface CheckoutSessionRequest {
    /**
     * @generated from protobuf field: optional MessageType type = 1;
     */
    type?: MessageType;
    /**
     * @generated from protobuf field: optional int32 version = 2;
     */
    version?: number;
    /**
     * @generated from protobuf field: string itemId = 3;
     */
    itemId: string;
}
/**
 * @generated from protobuf message CheckoutSessionResponse
 */
export interface CheckoutSessionResponse {
    /**
     * @generated from protobuf field: optional int32 version = 1;
     */
    version?: number;
    /**
     * @generated from protobuf field: optional string error = 2;
     */
    error?: string;
    /**
     * @generated from protobuf field: optional string client_secret = 3;
     */
    clientSecret?: string;
    /**
     * @generated from protobuf field: optional string redirect_url = 4;
     */
    redirectUrl?: string;
}
/**
 * @generated from protobuf message PurchaseCompletedRequest
 */
export interface PurchaseCompletedRequest {
    /**
     * @generated from protobuf field: optional MessageType type = 1;
     */
    type?: MessageType;
    /**
     * @generated from protobuf field: optional int32 version = 2;
     */
    version?: number;
    /**
     * @generated from protobuf field: string session_id = 3;
     */
    sessionId: string;
}
/**
 * @generated from protobuf message PurchaseCompletedResponse
 */
export interface PurchaseCompletedResponse {
    /**
     * @generated from protobuf field: optional int32 version = 1;
     */
    version?: number;
    /**
     * @generated from protobuf field: optional string error = 2;
     */
    error?: string;
    /**
     * @generated from protobuf field: optional int32 num_credits_purchased = 5;
     */
    numCreditsPurchased?: number;
    /**
     * Credits
     *
     * @generated from protobuf field: optional int32 num_credits_available = 3;
     */
    numCreditsAvailable?: number;
    /**
     * @generated from protobuf field: repeated int32 resort_uid = 4;
     */
    resortUid: number[];
}
/**
 * END-WEB
 * START-WEB
 *
 * @generated from protobuf message GetResortsRequest
 */
export interface GetResortsRequest {
    /**
     * @generated from protobuf field: optional MessageType type = 1;
     */
    type?: MessageType;
    /**
     * @generated from protobuf field: optional int32 version = 2;
     */
    version?: number;
    /**
     * @generated from protobuf field: int32 resort_uid = 6;
     */
    resortUid: number; // END-WEB
    // START-WEB
}
/**
 * @generated from protobuf message GetResortsResponse
 */
export interface GetResortsResponse {
    /**
     * @generated from protobuf field: optional int32 version = 1;
     */
    version?: number;
    /**
     * An error message is present if things go wrong.
     *
     * @generated from protobuf field: optional string error = 3;
     */
    error?: string;
    /**
     * @generated from protobuf field: optional Resort resort = 4;
     */
    resort?: Resort;
    /**
     * @generated from protobuf field: optional bool has_heatmap = 10;
     */
    hasHeatmap?: boolean; // END-WEB
    // START-WEB
}
/**
 * END-WEB
 * START-WEB
 *
 * @generated from protobuf message GetHeatmapRequest
 */
export interface GetHeatmapRequest {
    /**
     * @generated from protobuf field: optional MessageType type = 1;
     */
    type?: MessageType;
    /**
     * @generated from protobuf field: int32 resort_uid = 2;
     */
    resortUid: number;
}
/**
 * @generated from protobuf message GetHeatmapResponse
 */
export interface GetHeatmapResponse {
    /**
     * @generated from protobuf field: optional string error = 1;
     */
    error?: string;
    /**
     * @generated from protobuf field: optional int64 heatmap_identifier = 2;
     */
    heatmapIdentifier?: string;
    /**
     * @generated from protobuf field: optional GPSPoint nw = 3;
     */
    nw?: GPSPoint;
    /**
     * @generated from protobuf field: optional GPSPoint ne = 4;
     */
    ne?: GPSPoint;
    /**
     * @generated from protobuf field: optional GPSPoint se = 5;
     */
    se?: GPSPoint;
    /**
     * @generated from protobuf field: optional GPSPoint sw = 6;
     */
    sw?: GPSPoint;
}
/**
 * END-WEB
 * START-WEB
 *
 * @generated from protobuf message DeleteUserResponse
 */
export interface DeleteUserResponse {
    /**
     * @generated from protobuf field: optional string error = 1;
     */
    error?: string;
}
/**
 * Type for protobuf message Empty
 */
class Empty$Type extends MessageType$<Empty> {
    constructor() {
        super("Empty", [
            { no: 1, name: "type", kind: "enum", opt: true, T: () => ["MessageType", MessageType] }
        ]);
    }
    create(value?: PartialMessage<Empty>): Empty {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<Empty>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Empty): Empty {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional MessageType type */ 1:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional MessageType type = 1; */
        if (message.type !== undefined)
            writer.tag(1, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Empty = new Empty$Type();
/**
 * Type for protobuf message AllResorts2
 */
class AllResorts2$Type extends MessageType$<AllResorts2> {
    constructor() {
        super("AllResorts2", [
            { no: 1, name: "type", kind: "enum", opt: true, T: () => ["MessageType", MessageType] },
            { no: 2, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "continents", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => AllResorts2_Geometry },
            { no: 5, name: "countries", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => AllResorts2_Geometry },
            { no: 3, name: "resorts", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => AllResorts2_Resort }
        ]);
    }
    create(value?: PartialMessage<AllResorts2>): AllResorts2 {
        const message = { continents: [], countries: [], resorts: [] };
        if (value !== undefined)
            reflectionMergePartial<AllResorts2>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllResorts2): AllResorts2 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional MessageType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* optional int32 version */ 2:
                    message.version = reader.int32();
                    break;
                case /* repeated AllResorts2.Geometry continents */ 4:
                    message.continents.push(AllResorts2_Geometry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated AllResorts2.Geometry countries */ 5:
                    message.countries.push(AllResorts2_Geometry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated AllResorts2.Resort resorts */ 3:
                    message.resorts.push(AllResorts2_Resort.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllResorts2, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional MessageType type = 1; */
        if (message.type !== undefined)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* optional int32 version = 2; */
        if (message.version !== undefined)
            writer.tag(2, WireType.Varint).int32(message.version);
        /* repeated AllResorts2.Geometry continents = 4; */
        for (let i = 0; i < message.continents.length; i++)
            AllResorts2_Geometry.internalBinaryWrite(message.continents[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated AllResorts2.Geometry countries = 5; */
        for (let i = 0; i < message.countries.length; i++)
            AllResorts2_Geometry.internalBinaryWrite(message.countries[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated AllResorts2.Resort resorts = 3; */
        for (let i = 0; i < message.resorts.length; i++)
            AllResorts2_Resort.internalBinaryWrite(message.resorts[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const AllResorts2 = new AllResorts2$Type();
/**
 * Type for protobuf message AllResorts2.Map
 */
class AllResorts2_Map$Type extends MessageType$<AllResorts2_Map> {
    constructor() {
        super("AllResorts2.Map", [
            { no: 3, name: "image_identifier", kind: "scalar", opt: true, T: 3 /*ScalarType.INT64*/ }
        ]);
    }
    create(value?: PartialMessage<AllResorts2_Map>): AllResorts2_Map {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<AllResorts2_Map>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllResorts2_Map): AllResorts2_Map {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional int64 image_identifier */ 3:
                    message.imageIdentifier = reader.int64().toString();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllResorts2_Map, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional int64 image_identifier = 3; */
        if (message.imageIdentifier !== undefined)
            writer.tag(3, WireType.Varint).int64(message.imageIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const AllResorts2_Map = new AllResorts2_Map$Type();
/**
 * Type for protobuf message AllResorts2.Geometry
 */
class AllResorts2_Geometry$Type extends MessageType$<AllResorts2_Geometry> {
    constructor() {
        super("AllResorts2.Geometry", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "polygon", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => GPSPointArray }
        ]);
    }
    create(value?: PartialMessage<AllResorts2_Geometry>): AllResorts2_Geometry {
        const message = { name: "", polygon: [] };
        if (value !== undefined)
            reflectionMergePartial<AllResorts2_Geometry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllResorts2_Geometry): AllResorts2_Geometry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* repeated GPSPointArray polygon */ 2:
                    message.polygon.push(GPSPointArray.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllResorts2_Geometry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* repeated GPSPointArray polygon = 2; */
        for (let i = 0; i < message.polygon.length; i++)
            GPSPointArray.internalBinaryWrite(message.polygon[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const AllResorts2_Geometry = new AllResorts2_Geometry$Type();
/**
 * Type for protobuf message AllResorts2.Resort
 */
class AllResorts2_Resort$Type extends MessageType$<AllResorts2_Resort> {
    constructor() {
        super("AllResorts2.Resort", [
            { no: 8, name: "resort_uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "name_ja", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "name_cyrillic", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "continent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "countries", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "states", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "centroid", kind: "message", T: () => GPSPoint },
            { no: 11, name: "radiusE2", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "northeast", kind: "message", T: () => GPSPoint },
            { no: 24, name: "southwest", kind: "message", T: () => GPSPoint },
            { no: 12, name: "timezone", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "number_of_lifts", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "base_elevationE2", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "top_elevationE2", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "most_vertical_for_a_liftE2", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "number_of_skiruns", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "total_length_of_skirunsE2", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "length_of_longest_skirunE2", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "most_vertical_for_a_skirunE2", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "season_is_open", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ },
            { no: 26, name: "season_start", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "season_end", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "snow", kind: "message", T: () => ResortSnow },
            { no: 7, name: "maps_obsolete", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => AllResorts2_Map },
            { no: 29, name: "previous_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "difficulties", kind: "enum", repeat: 2 /*RepeatType.UNPACKED*/, T: () => ["SkiRunDifficulty", SkiRunDifficulty] },
            { no: 31, name: "boundary", kind: "message", T: () => GPSPointArray }
        ]);
    }
    create(value?: PartialMessage<AllResorts2_Resort>): AllResorts2_Resort {
        const message = { resortUid: 0, name: "", continent: "", countries: [], states: [], mapsObsolete: [], previousNames: [], difficulties: [] };
        if (value !== undefined)
            reflectionMergePartial<AllResorts2_Resort>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllResorts2_Resort): AllResorts2_Resort {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 resort_uid */ 8:
                    message.resortUid = reader.int32();
                    break;
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* optional string name_ja */ 13:
                    message.nameJa = reader.string();
                    break;
                case /* optional string name_cyrillic */ 14:
                    message.nameCyrillic = reader.string();
                    break;
                case /* string continent */ 3:
                    message.continent = reader.string();
                    break;
                case /* repeated string countries */ 4:
                    message.countries.push(reader.string());
                    break;
                case /* repeated string states */ 5:
                    message.states.push(reader.string());
                    break;
                case /* optional GPSPoint centroid */ 9:
                    message.centroid = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.centroid);
                    break;
                case /* optional int32 radiusE2 */ 11:
                    message.radiusE2 = reader.int32();
                    break;
                case /* optional GPSPoint northeast */ 23:
                    message.northeast = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.northeast);
                    break;
                case /* optional GPSPoint southwest */ 24:
                    message.southwest = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.southwest);
                    break;
                case /* optional string timezone */ 12:
                    message.timezone = reader.string();
                    break;
                case /* optional int32 number_of_lifts */ 15:
                    message.numberOfLifts = reader.int32();
                    break;
                case /* optional int32 base_elevationE2 */ 16:
                    message.baseElevationE2 = reader.int32();
                    break;
                case /* optional int32 top_elevationE2 */ 17:
                    message.topElevationE2 = reader.int32();
                    break;
                case /* optional int32 most_vertical_for_a_liftE2 */ 18:
                    message.mostVerticalForALiftE2 = reader.int32();
                    break;
                case /* optional int32 number_of_skiruns */ 19:
                    message.numberOfSkiruns = reader.int32();
                    break;
                case /* optional int32 total_length_of_skirunsE2 */ 20:
                    message.totalLengthOfSkirunsE2 = reader.int32();
                    break;
                case /* optional int32 length_of_longest_skirunE2 */ 21:
                    message.lengthOfLongestSkirunE2 = reader.int32();
                    break;
                case /* optional int32 most_vertical_for_a_skirunE2 */ 22:
                    message.mostVerticalForASkirunE2 = reader.int32();
                    break;
                case /* optional bool season_is_open */ 25:
                    message.seasonIsOpen = reader.bool();
                    break;
                case /* optional string season_start */ 26:
                    message.seasonStart = reader.string();
                    break;
                case /* optional string season_end */ 27:
                    message.seasonEnd = reader.string();
                    break;
                case /* optional ResortSnow snow */ 28:
                    message.snow = ResortSnow.internalBinaryRead(reader, reader.uint32(), options, message.snow);
                    break;
                case /* repeated AllResorts2.Map maps_obsolete */ 7:
                    message.mapsObsolete.push(AllResorts2_Map.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string previous_names */ 29:
                    message.previousNames.push(reader.string());
                    break;
                case /* repeated SkiRunDifficulty difficulties */ 30:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.difficulties.push(reader.int32());
                    else
                        message.difficulties.push(reader.int32());
                    break;
                case /* optional GPSPointArray boundary */ 31:
                    message.boundary = GPSPointArray.internalBinaryRead(reader, reader.uint32(), options, message.boundary);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllResorts2_Resort, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 resort_uid = 8; */
        if (message.resortUid !== 0)
            writer.tag(8, WireType.Varint).int32(message.resortUid);
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* optional string name_ja = 13; */
        if (message.nameJa !== undefined)
            writer.tag(13, WireType.LengthDelimited).string(message.nameJa);
        /* optional string name_cyrillic = 14; */
        if (message.nameCyrillic !== undefined)
            writer.tag(14, WireType.LengthDelimited).string(message.nameCyrillic);
        /* string continent = 3; */
        if (message.continent !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.continent);
        /* repeated string countries = 4; */
        for (let i = 0; i < message.countries.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.countries[i]);
        /* repeated string states = 5; */
        for (let i = 0; i < message.states.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.states[i]);
        /* optional GPSPoint centroid = 9; */
        if (message.centroid)
            GPSPoint.internalBinaryWrite(message.centroid, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* optional int32 radiusE2 = 11; */
        if (message.radiusE2 !== undefined)
            writer.tag(11, WireType.Varint).int32(message.radiusE2);
        /* optional GPSPoint northeast = 23; */
        if (message.northeast)
            GPSPoint.internalBinaryWrite(message.northeast, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* optional GPSPoint southwest = 24; */
        if (message.southwest)
            GPSPoint.internalBinaryWrite(message.southwest, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* optional string timezone = 12; */
        if (message.timezone !== undefined)
            writer.tag(12, WireType.LengthDelimited).string(message.timezone);
        /* optional int32 number_of_lifts = 15; */
        if (message.numberOfLifts !== undefined)
            writer.tag(15, WireType.Varint).int32(message.numberOfLifts);
        /* optional int32 base_elevationE2 = 16; */
        if (message.baseElevationE2 !== undefined)
            writer.tag(16, WireType.Varint).int32(message.baseElevationE2);
        /* optional int32 top_elevationE2 = 17; */
        if (message.topElevationE2 !== undefined)
            writer.tag(17, WireType.Varint).int32(message.topElevationE2);
        /* optional int32 most_vertical_for_a_liftE2 = 18; */
        if (message.mostVerticalForALiftE2 !== undefined)
            writer.tag(18, WireType.Varint).int32(message.mostVerticalForALiftE2);
        /* optional int32 number_of_skiruns = 19; */
        if (message.numberOfSkiruns !== undefined)
            writer.tag(19, WireType.Varint).int32(message.numberOfSkiruns);
        /* optional int32 total_length_of_skirunsE2 = 20; */
        if (message.totalLengthOfSkirunsE2 !== undefined)
            writer.tag(20, WireType.Varint).int32(message.totalLengthOfSkirunsE2);
        /* optional int32 length_of_longest_skirunE2 = 21; */
        if (message.lengthOfLongestSkirunE2 !== undefined)
            writer.tag(21, WireType.Varint).int32(message.lengthOfLongestSkirunE2);
        /* optional int32 most_vertical_for_a_skirunE2 = 22; */
        if (message.mostVerticalForASkirunE2 !== undefined)
            writer.tag(22, WireType.Varint).int32(message.mostVerticalForASkirunE2);
        /* optional bool season_is_open = 25; */
        if (message.seasonIsOpen !== undefined)
            writer.tag(25, WireType.Varint).bool(message.seasonIsOpen);
        /* optional string season_start = 26; */
        if (message.seasonStart !== undefined)
            writer.tag(26, WireType.LengthDelimited).string(message.seasonStart);
        /* optional string season_end = 27; */
        if (message.seasonEnd !== undefined)
            writer.tag(27, WireType.LengthDelimited).string(message.seasonEnd);
        /* optional ResortSnow snow = 28; */
        if (message.snow)
            ResortSnow.internalBinaryWrite(message.snow, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* repeated AllResorts2.Map maps_obsolete = 7; */
        for (let i = 0; i < message.mapsObsolete.length; i++)
            AllResorts2_Map.internalBinaryWrite(message.mapsObsolete[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated string previous_names = 29; */
        for (let i = 0; i < message.previousNames.length; i++)
            writer.tag(29, WireType.LengthDelimited).string(message.previousNames[i]);
        /* repeated SkiRunDifficulty difficulties = 30; */
        for (let i = 0; i < message.difficulties.length; i++)
            writer.tag(30, WireType.Varint).int32(message.difficulties[i]);
        /* optional GPSPointArray boundary = 31; */
        if (message.boundary)
            GPSPointArray.internalBinaryWrite(message.boundary, writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const AllResorts2_Resort = new AllResorts2_Resort$Type();
/**
 * Type for protobuf message AllResortBoundaries
 */
class AllResortBoundaries$Type extends MessageType$<AllResortBoundaries> {
    constructor() {
        super("AllResortBoundaries", [
            { no: 1, name: "type", kind: "enum", opt: true, T: () => ["MessageType", MessageType] },
            { no: 2, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "continents", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => AllResortBoundaries_Geometry },
            { no: 4, name: "countries", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => AllResortBoundaries_Geometry },
            { no: 5, name: "resorts", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => AllResortBoundaries_Resort }
        ]);
    }
    create(value?: PartialMessage<AllResortBoundaries>): AllResortBoundaries {
        const message = { continents: [], countries: [], resorts: [] };
        if (value !== undefined)
            reflectionMergePartial<AllResortBoundaries>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllResortBoundaries): AllResortBoundaries {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional MessageType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* optional int32 version */ 2:
                    message.version = reader.int32();
                    break;
                case /* repeated AllResortBoundaries.Geometry continents */ 3:
                    message.continents.push(AllResortBoundaries_Geometry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated AllResortBoundaries.Geometry countries */ 4:
                    message.countries.push(AllResortBoundaries_Geometry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated AllResortBoundaries.Resort resorts */ 5:
                    message.resorts.push(AllResortBoundaries_Resort.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllResortBoundaries, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional MessageType type = 1; */
        if (message.type !== undefined)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* optional int32 version = 2; */
        if (message.version !== undefined)
            writer.tag(2, WireType.Varint).int32(message.version);
        /* repeated AllResortBoundaries.Geometry continents = 3; */
        for (let i = 0; i < message.continents.length; i++)
            AllResortBoundaries_Geometry.internalBinaryWrite(message.continents[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated AllResortBoundaries.Geometry countries = 4; */
        for (let i = 0; i < message.countries.length; i++)
            AllResortBoundaries_Geometry.internalBinaryWrite(message.countries[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated AllResortBoundaries.Resort resorts = 5; */
        for (let i = 0; i < message.resorts.length; i++)
            AllResortBoundaries_Resort.internalBinaryWrite(message.resorts[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const AllResortBoundaries = new AllResortBoundaries$Type();
/**
 * Type for protobuf message AllResortBoundaries.Geometry
 */
class AllResortBoundaries_Geometry$Type extends MessageType$<AllResortBoundaries_Geometry> {
    constructor() {
        super("AllResortBoundaries.Geometry", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "polygon", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => GPSPointArray }
        ]);
    }
    create(value?: PartialMessage<AllResortBoundaries_Geometry>): AllResortBoundaries_Geometry {
        const message = { name: "", polygon: [] };
        if (value !== undefined)
            reflectionMergePartial<AllResortBoundaries_Geometry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllResortBoundaries_Geometry): AllResortBoundaries_Geometry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* repeated GPSPointArray polygon */ 2:
                    message.polygon.push(GPSPointArray.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllResortBoundaries_Geometry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* repeated GPSPointArray polygon = 2; */
        for (let i = 0; i < message.polygon.length; i++)
            GPSPointArray.internalBinaryWrite(message.polygon[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const AllResortBoundaries_Geometry = new AllResortBoundaries_Geometry$Type();
/**
 * Type for protobuf message AllResortBoundaries.Resort
 */
class AllResortBoundaries_Resort$Type extends MessageType$<AllResortBoundaries_Resort> {
    constructor() {
        super("AllResortBoundaries.Resort", [
            { no: 1, name: "resort_uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "difficulties", kind: "enum", repeat: 2 /*RepeatType.UNPACKED*/, T: () => ["SkiRunDifficulty", SkiRunDifficulty] },
            { no: 3, name: "boundary", kind: "message", T: () => GPSPointArray }
        ]);
    }
    create(value?: PartialMessage<AllResortBoundaries_Resort>): AllResortBoundaries_Resort {
        const message = { resortUid: 0, difficulties: [] };
        if (value !== undefined)
            reflectionMergePartial<AllResortBoundaries_Resort>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllResortBoundaries_Resort): AllResortBoundaries_Resort {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 resort_uid */ 1:
                    message.resortUid = reader.int32();
                    break;
                case /* repeated SkiRunDifficulty difficulties */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.difficulties.push(reader.int32());
                    else
                        message.difficulties.push(reader.int32());
                    break;
                case /* GPSPointArray boundary */ 3:
                    message.boundary = GPSPointArray.internalBinaryRead(reader, reader.uint32(), options, message.boundary);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllResortBoundaries_Resort, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 resort_uid = 1; */
        if (message.resortUid !== 0)
            writer.tag(1, WireType.Varint).int32(message.resortUid);
        /* repeated SkiRunDifficulty difficulties = 2; */
        for (let i = 0; i < message.difficulties.length; i++)
            writer.tag(2, WireType.Varint).int32(message.difficulties[i]);
        /* GPSPointArray boundary = 3; */
        if (message.boundary)
            GPSPointArray.internalBinaryWrite(message.boundary, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const AllResortBoundaries_Resort = new AllResortBoundaries_Resort$Type();
/**
 * Type for protobuf message UserRequest
 */
class UserRequest$Type extends MessageType$<UserRequest> {
    constructor() {
        super("UserRequest", [
            { no: 1, name: "type", kind: "enum", opt: true, T: () => ["MessageType", MessageType] },
            { no: 2, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "distance_units", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "elevation_units", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "temperature_units", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "image", kind: "scalar", opt: true, T: 12 /*ScalarType.BYTES*/ },
            { no: 7, name: "mime_type", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserRequest>): UserRequest {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<UserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRequest): UserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional MessageType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* optional int32 version */ 2:
                    message.version = reader.int32();
                    break;
                case /* optional string distance_units */ 3:
                    message.distanceUnits = reader.string();
                    break;
                case /* optional string elevation_units */ 4:
                    message.elevationUnits = reader.string();
                    break;
                case /* optional string temperature_units */ 5:
                    message.temperatureUnits = reader.string();
                    break;
                case /* optional bytes image */ 6:
                    message.image = reader.bytes();
                    break;
                case /* optional string mime_type */ 7:
                    message.mimeType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional MessageType type = 1; */
        if (message.type !== undefined)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* optional int32 version = 2; */
        if (message.version !== undefined)
            writer.tag(2, WireType.Varint).int32(message.version);
        /* optional string distance_units = 3; */
        if (message.distanceUnits !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.distanceUnits);
        /* optional string elevation_units = 4; */
        if (message.elevationUnits !== undefined)
            writer.tag(4, WireType.LengthDelimited).string(message.elevationUnits);
        /* optional string temperature_units = 5; */
        if (message.temperatureUnits !== undefined)
            writer.tag(5, WireType.LengthDelimited).string(message.temperatureUnits);
        /* optional bytes image = 6; */
        if (message.image !== undefined)
            writer.tag(6, WireType.LengthDelimited).bytes(message.image);
        /* optional string mime_type = 7; */
        if (message.mimeType !== undefined)
            writer.tag(7, WireType.LengthDelimited).string(message.mimeType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const UserRequest = new UserRequest$Type();
/**
 * Type for protobuf message UserResponse
 */
class UserResponse$Type extends MessageType$<UserResponse> {
    constructor() {
        super("UserResponse", [
            { no: 1, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "error", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "distance_units", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "elevation_units", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "temperature_units", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "extra_routes", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => UserResponse_Route },
            { no: 6, name: "extra_menu_title", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "small_image", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "large_image", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "num_credits_available", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "resort_uid", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UserResponse>): UserResponse {
        const message = { extraRoutes: [], resortUid: [] };
        if (value !== undefined)
            reflectionMergePartial<UserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserResponse): UserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional int32 version */ 1:
                    message.version = reader.int32();
                    break;
                case /* optional string error */ 7:
                    message.error = reader.string();
                    break;
                case /* optional string distance_units */ 2:
                    message.distanceUnits = reader.string();
                    break;
                case /* optional string elevation_units */ 3:
                    message.elevationUnits = reader.string();
                    break;
                case /* optional string temperature_units */ 4:
                    message.temperatureUnits = reader.string();
                    break;
                case /* repeated UserResponse.Route extra_routes */ 5:
                    message.extraRoutes.push(UserResponse_Route.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* optional string extra_menu_title */ 6:
                    message.extraMenuTitle = reader.string();
                    break;
                case /* optional string small_image */ 8:
                    message.smallImage = reader.string();
                    break;
                case /* optional string large_image */ 9:
                    message.largeImage = reader.string();
                    break;
                case /* optional int32 num_credits_available */ 10:
                    message.numCreditsAvailable = reader.int32();
                    break;
                case /* repeated int32 resort_uid */ 11:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.resortUid.push(reader.int32());
                    else
                        message.resortUid.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional int32 version = 1; */
        if (message.version !== undefined)
            writer.tag(1, WireType.Varint).int32(message.version);
        /* optional string error = 7; */
        if (message.error !== undefined)
            writer.tag(7, WireType.LengthDelimited).string(message.error);
        /* optional string distance_units = 2; */
        if (message.distanceUnits !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.distanceUnits);
        /* optional string elevation_units = 3; */
        if (message.elevationUnits !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.elevationUnits);
        /* optional string temperature_units = 4; */
        if (message.temperatureUnits !== undefined)
            writer.tag(4, WireType.LengthDelimited).string(message.temperatureUnits);
        /* repeated UserResponse.Route extra_routes = 5; */
        for (let i = 0; i < message.extraRoutes.length; i++)
            UserResponse_Route.internalBinaryWrite(message.extraRoutes[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* optional string extra_menu_title = 6; */
        if (message.extraMenuTitle !== undefined)
            writer.tag(6, WireType.LengthDelimited).string(message.extraMenuTitle);
        /* optional string small_image = 8; */
        if (message.smallImage !== undefined)
            writer.tag(8, WireType.LengthDelimited).string(message.smallImage);
        /* optional string large_image = 9; */
        if (message.largeImage !== undefined)
            writer.tag(9, WireType.LengthDelimited).string(message.largeImage);
        /* optional int32 num_credits_available = 10; */
        if (message.numCreditsAvailable !== undefined)
            writer.tag(10, WireType.Varint).int32(message.numCreditsAvailable);
        /* repeated int32 resort_uid = 11; */
        for (let i = 0; i < message.resortUid.length; i++)
            writer.tag(11, WireType.Varint).int32(message.resortUid[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const UserResponse = new UserResponse$Type();
/**
 * Type for protobuf message UserResponse.Route
 */
class UserResponse_Route$Type extends MessageType$<UserResponse_Route> {
    constructor() {
        super("UserResponse.Route", [
            { no: 3, name: "module_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserResponse_Route>): UserResponse_Route {
        const message = { moduleName: "", url: "", label: "" };
        if (value !== undefined)
            reflectionMergePartial<UserResponse_Route>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserResponse_Route): UserResponse_Route {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string module_name */ 3:
                    message.moduleName = reader.string();
                    break;
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserResponse_Route, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string module_name = 3; */
        if (message.moduleName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.moduleName);
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const UserResponse_Route = new UserResponse_Route$Type();
/**
 * Type for protobuf message GetResortsResponse2
 */
class GetResortsResponse2$Type extends MessageType$<GetResortsResponse2> {
    constructor() {
        super("GetResortsResponse2", [
            { no: 1, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "error", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "resort", kind: "message", T: () => Resort },
            { no: 10, name: "has_heatmap", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "snow", kind: "message", T: () => ResortSnow },
            { no: 5, name: "observations", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => WeatherStationObservation }
        ]);
    }
    create(value?: PartialMessage<GetResortsResponse2>): GetResortsResponse2 {
        const message = { observations: [] };
        if (value !== undefined)
            reflectionMergePartial<GetResortsResponse2>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResortsResponse2): GetResortsResponse2 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional int32 version */ 1:
                    message.version = reader.int32();
                    break;
                case /* optional string error */ 3:
                    message.error = reader.string();
                    break;
                case /* optional Resort resort */ 4:
                    message.resort = Resort.internalBinaryRead(reader, reader.uint32(), options, message.resort);
                    break;
                case /* optional bool has_heatmap */ 10:
                    message.hasHeatmap = reader.bool();
                    break;
                case /* optional ResortSnow snow */ 6:
                    message.snow = ResortSnow.internalBinaryRead(reader, reader.uint32(), options, message.snow);
                    break;
                case /* repeated WeatherStationObservation observations */ 5:
                    message.observations.push(WeatherStationObservation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetResortsResponse2, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional int32 version = 1; */
        if (message.version !== undefined)
            writer.tag(1, WireType.Varint).int32(message.version);
        /* optional string error = 3; */
        if (message.error !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.error);
        /* optional Resort resort = 4; */
        if (message.resort)
            Resort.internalBinaryWrite(message.resort, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* optional bool has_heatmap = 10; */
        if (message.hasHeatmap !== undefined)
            writer.tag(10, WireType.Varint).bool(message.hasHeatmap);
        /* optional ResortSnow snow = 6; */
        if (message.snow)
            ResortSnow.internalBinaryWrite(message.snow, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated WeatherStationObservation observations = 5; */
        for (let i = 0; i < message.observations.length; i++)
            WeatherStationObservation.internalBinaryWrite(message.observations[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GetResortsResponse2 = new GetResortsResponse2$Type();
/**
 * Type for protobuf message CheckoutSessionRequest
 */
class CheckoutSessionRequest$Type extends MessageType$<CheckoutSessionRequest> {
    constructor() {
        super("CheckoutSessionRequest", [
            { no: 1, name: "type", kind: "enum", opt: true, T: () => ["MessageType", MessageType] },
            { no: 2, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "itemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckoutSessionRequest>): CheckoutSessionRequest {
        const message = { itemId: "" };
        if (value !== undefined)
            reflectionMergePartial<CheckoutSessionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckoutSessionRequest): CheckoutSessionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional MessageType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* optional int32 version */ 2:
                    message.version = reader.int32();
                    break;
                case /* string itemId */ 3:
                    message.itemId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckoutSessionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional MessageType type = 1; */
        if (message.type !== undefined)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* optional int32 version = 2; */
        if (message.version !== undefined)
            writer.tag(2, WireType.Varint).int32(message.version);
        /* string itemId = 3; */
        if (message.itemId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.itemId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const CheckoutSessionRequest = new CheckoutSessionRequest$Type();
/**
 * Type for protobuf message CheckoutSessionResponse
 */
class CheckoutSessionResponse$Type extends MessageType$<CheckoutSessionResponse> {
    constructor() {
        super("CheckoutSessionResponse", [
            { no: 1, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "error", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "client_secret", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "redirect_url", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckoutSessionResponse>): CheckoutSessionResponse {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<CheckoutSessionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckoutSessionResponse): CheckoutSessionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional int32 version */ 1:
                    message.version = reader.int32();
                    break;
                case /* optional string error */ 2:
                    message.error = reader.string();
                    break;
                case /* optional string client_secret */ 3:
                    message.clientSecret = reader.string();
                    break;
                case /* optional string redirect_url */ 4:
                    message.redirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckoutSessionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional int32 version = 1; */
        if (message.version !== undefined)
            writer.tag(1, WireType.Varint).int32(message.version);
        /* optional string error = 2; */
        if (message.error !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.error);
        /* optional string client_secret = 3; */
        if (message.clientSecret !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.clientSecret);
        /* optional string redirect_url = 4; */
        if (message.redirectUrl !== undefined)
            writer.tag(4, WireType.LengthDelimited).string(message.redirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const CheckoutSessionResponse = new CheckoutSessionResponse$Type();
/**
 * Type for protobuf message PurchaseCompletedRequest
 */
class PurchaseCompletedRequest$Type extends MessageType$<PurchaseCompletedRequest> {
    constructor() {
        super("PurchaseCompletedRequest", [
            { no: 1, name: "type", kind: "enum", opt: true, T: () => ["MessageType", MessageType] },
            { no: 2, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PurchaseCompletedRequest>): PurchaseCompletedRequest {
        const message = { sessionId: "" };
        if (value !== undefined)
            reflectionMergePartial<PurchaseCompletedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PurchaseCompletedRequest): PurchaseCompletedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional MessageType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* optional int32 version */ 2:
                    message.version = reader.int32();
                    break;
                case /* string session_id */ 3:
                    message.sessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PurchaseCompletedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional MessageType type = 1; */
        if (message.type !== undefined)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* optional int32 version = 2; */
        if (message.version !== undefined)
            writer.tag(2, WireType.Varint).int32(message.version);
        /* string session_id = 3; */
        if (message.sessionId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.sessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const PurchaseCompletedRequest = new PurchaseCompletedRequest$Type();
/**
 * Type for protobuf message PurchaseCompletedResponse
 */
class PurchaseCompletedResponse$Type extends MessageType$<PurchaseCompletedResponse> {
    constructor() {
        super("PurchaseCompletedResponse", [
            { no: 1, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "error", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "num_credits_purchased", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "num_credits_available", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "resort_uid", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PurchaseCompletedResponse>): PurchaseCompletedResponse {
        const message = { resortUid: [] };
        if (value !== undefined)
            reflectionMergePartial<PurchaseCompletedResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PurchaseCompletedResponse): PurchaseCompletedResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional int32 version */ 1:
                    message.version = reader.int32();
                    break;
                case /* optional string error */ 2:
                    message.error = reader.string();
                    break;
                case /* optional int32 num_credits_purchased */ 5:
                    message.numCreditsPurchased = reader.int32();
                    break;
                case /* optional int32 num_credits_available */ 3:
                    message.numCreditsAvailable = reader.int32();
                    break;
                case /* repeated int32 resort_uid */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.resortUid.push(reader.int32());
                    else
                        message.resortUid.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PurchaseCompletedResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional int32 version = 1; */
        if (message.version !== undefined)
            writer.tag(1, WireType.Varint).int32(message.version);
        /* optional string error = 2; */
        if (message.error !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.error);
        /* optional int32 num_credits_purchased = 5; */
        if (message.numCreditsPurchased !== undefined)
            writer.tag(5, WireType.Varint).int32(message.numCreditsPurchased);
        /* optional int32 num_credits_available = 3; */
        if (message.numCreditsAvailable !== undefined)
            writer.tag(3, WireType.Varint).int32(message.numCreditsAvailable);
        /* repeated int32 resort_uid = 4; */
        for (let i = 0; i < message.resortUid.length; i++)
            writer.tag(4, WireType.Varint).int32(message.resortUid[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const PurchaseCompletedResponse = new PurchaseCompletedResponse$Type();
/**
 * Type for protobuf message GetResortsRequest
 */
class GetResortsRequest$Type extends MessageType$<GetResortsRequest> {
    constructor() {
        super("GetResortsRequest", [
            { no: 1, name: "type", kind: "enum", opt: true, T: () => ["MessageType", MessageType] },
            { no: 2, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "resort_uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetResortsRequest>): GetResortsRequest {
        const message = { resortUid: 0 };
        if (value !== undefined)
            reflectionMergePartial<GetResortsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResortsRequest): GetResortsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional MessageType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* optional int32 version */ 2:
                    message.version = reader.int32();
                    break;
                case /* int32 resort_uid */ 6:
                    message.resortUid = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetResortsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional MessageType type = 1; */
        if (message.type !== undefined)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* optional int32 version = 2; */
        if (message.version !== undefined)
            writer.tag(2, WireType.Varint).int32(message.version);
        /* int32 resort_uid = 6; */
        if (message.resortUid !== 0)
            writer.tag(6, WireType.Varint).int32(message.resortUid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GetResortsRequest = new GetResortsRequest$Type();
/**
 * Type for protobuf message GetResortsResponse
 */
class GetResortsResponse$Type extends MessageType$<GetResortsResponse> {
    constructor() {
        super("GetResortsResponse", [
            { no: 1, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "error", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "resort", kind: "message", T: () => Resort },
            { no: 10, name: "has_heatmap", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetResortsResponse>): GetResortsResponse {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<GetResortsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResortsResponse): GetResortsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional int32 version */ 1:
                    message.version = reader.int32();
                    break;
                case /* optional string error */ 3:
                    message.error = reader.string();
                    break;
                case /* optional Resort resort */ 4:
                    message.resort = Resort.internalBinaryRead(reader, reader.uint32(), options, message.resort);
                    break;
                case /* optional bool has_heatmap */ 10:
                    message.hasHeatmap = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetResortsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional int32 version = 1; */
        if (message.version !== undefined)
            writer.tag(1, WireType.Varint).int32(message.version);
        /* optional string error = 3; */
        if (message.error !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.error);
        /* optional Resort resort = 4; */
        if (message.resort)
            Resort.internalBinaryWrite(message.resort, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* optional bool has_heatmap = 10; */
        if (message.hasHeatmap !== undefined)
            writer.tag(10, WireType.Varint).bool(message.hasHeatmap);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GetResortsResponse = new GetResortsResponse$Type();
/**
 * Type for protobuf message GetHeatmapRequest
 */
class GetHeatmapRequest$Type extends MessageType$<GetHeatmapRequest> {
    constructor() {
        super("GetHeatmapRequest", [
            { no: 1, name: "type", kind: "enum", opt: true, T: () => ["MessageType", MessageType] },
            { no: 2, name: "resort_uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetHeatmapRequest>): GetHeatmapRequest {
        const message = { resortUid: 0 };
        if (value !== undefined)
            reflectionMergePartial<GetHeatmapRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHeatmapRequest): GetHeatmapRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional MessageType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* int32 resort_uid */ 2:
                    message.resortUid = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHeatmapRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional MessageType type = 1; */
        if (message.type !== undefined)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* int32 resort_uid = 2; */
        if (message.resortUid !== 0)
            writer.tag(2, WireType.Varint).int32(message.resortUid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GetHeatmapRequest = new GetHeatmapRequest$Type();
/**
 * Type for protobuf message GetHeatmapResponse
 */
class GetHeatmapResponse$Type extends MessageType$<GetHeatmapResponse> {
    constructor() {
        super("GetHeatmapResponse", [
            { no: 1, name: "error", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "heatmap_identifier", kind: "scalar", opt: true, T: 3 /*ScalarType.INT64*/ },
            { no: 3, name: "nw", kind: "message", T: () => GPSPoint },
            { no: 4, name: "ne", kind: "message", T: () => GPSPoint },
            { no: 5, name: "se", kind: "message", T: () => GPSPoint },
            { no: 6, name: "sw", kind: "message", T: () => GPSPoint }
        ]);
    }
    create(value?: PartialMessage<GetHeatmapResponse>): GetHeatmapResponse {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<GetHeatmapResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHeatmapResponse): GetHeatmapResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string error */ 1:
                    message.error = reader.string();
                    break;
                case /* optional int64 heatmap_identifier */ 2:
                    message.heatmapIdentifier = reader.int64().toString();
                    break;
                case /* optional GPSPoint nw */ 3:
                    message.nw = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.nw);
                    break;
                case /* optional GPSPoint ne */ 4:
                    message.ne = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.ne);
                    break;
                case /* optional GPSPoint se */ 5:
                    message.se = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.se);
                    break;
                case /* optional GPSPoint sw */ 6:
                    message.sw = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.sw);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHeatmapResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string error = 1; */
        if (message.error !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.error);
        /* optional int64 heatmap_identifier = 2; */
        if (message.heatmapIdentifier !== undefined)
            writer.tag(2, WireType.Varint).int64(message.heatmapIdentifier);
        /* optional GPSPoint nw = 3; */
        if (message.nw)
            GPSPoint.internalBinaryWrite(message.nw, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* optional GPSPoint ne = 4; */
        if (message.ne)
            GPSPoint.internalBinaryWrite(message.ne, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* optional GPSPoint se = 5; */
        if (message.se)
            GPSPoint.internalBinaryWrite(message.se, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* optional GPSPoint sw = 6; */
        if (message.sw)
            GPSPoint.internalBinaryWrite(message.sw, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GetHeatmapResponse = new GetHeatmapResponse$Type();
/**
 * Type for protobuf message DeleteUserResponse
 */
class DeleteUserResponse$Type extends MessageType$<DeleteUserResponse> {
    constructor() {
        super("DeleteUserResponse", [
            { no: 1, name: "error", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteUserResponse>): DeleteUserResponse {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<DeleteUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteUserResponse): DeleteUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string error */ 1:
                    message.error = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string error = 1; */
        if (message.error !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.error);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const DeleteUserResponse = new DeleteUserResponse$Type();
