import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from "./auth.service";

import { UserResponse_Route } from './proto/requests';

const extraModules: any = {
    // The labeler module
    'l': {
        path: '',
        loadChildren: () => import('./labeler/labeler.module').then(m => m.LabelerModule),
    },

    // The blog admin module
    'b': {
        path: '',
        loadChildren: () => import('./blog/admin/blog-admin.module').then(m => m.BlogAdminModule),
    },

    // The blog admin module
    's': {
        path: '',
        loadChildren: () => import('./snow/snow.module').then(m => m.SnowModule),
    },
};

@Injectable({
    providedIn: 'root'
})
export class LazyLoaderService {
    constructor(
        private router: Router,
        private auth: AuthService,
    ) {
        console.log("LazyLoaderService constructor");

        this.auth.extraRoutes$
            .subscribe((routes: UserResponse_Route[]) => this.handleExtraRoutes(routes));
        this.handleExtraRoutes(this.auth.extraRoutes);
    }

    handleExtraRoutes(extraRoutes: UserResponse_Route[]) {
        console.log("Got extra routes", extraRoutes);
        if (!extraRoutes) {
            return;
        }
        // for (let route of extraRoutes) {
        //     this.loadLazyModule(route);
        // }
        let config = this.router.config;
        let changed: boolean = false;

        console.log("before update, router configuration", config);

        for (let extraRoute of extraRoutes) {
            // Iterate through the router's config looking for the
            // extraRoute.path. If it's not there, add it.
            let found: boolean = false;
            for (let route of config) {
                if (route.path == extraRoute.url) {
                    found = true;
                    break;
                }
            }
            // We haven't found the extraRoute.url in the router's
            // config. Lookup the extraRoute's module name in
            // extraModules, modify the corresponding configuration,
            // and add it at the beginning of the router's config.
            if (!found) {
                let module = extraModules[extraRoute.moduleName];
                if (module) {
                    module.path = extraRoute.url;
                    config.unshift(module);
                    changed = true;
                }
            }
        }

        if (changed) {
            this.router.resetConfig(config);
            console.log("after update, router configuration", config);
            this.router.initialNavigation();
        }
    }

}
