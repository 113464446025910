// import { Location } from '@angular/common';
import { HttpResponse, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import {
    Router, NavigationEnd, Event, ActivatedRoute, Params, NavigationStart,
    RouterEvent,
} from '@angular/router';

import { Injectable, EventEmitter, } from '@angular/core';

import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as LRUCache from 'lru-cache';

import {
    AllResorts2, GetResortsResponse2,
} from './proto/requests';
import { BackendService } from "./backend.service";
import { UtilsService } from "./utils.service";
import { UrlService, FOCUS } from "./utils/url.service";
import {
    World, Continent, Country, State, ResortBrief, Resort,
} from './datamodel';
import { WindowService } from './utils/window.service';

import { SearchEngine } from './utils/search';

import './types';

@Injectable({
    providedIn: 'root'
})
export class ResortsService {
    private _world?: World;
    public get world(): World | undefined { return this._world; }

    public world$: EventEmitter<World> = new EventEmitter();

    private _resort?: Resort;
    public get currentResort() { return this._resort; }

    private queryParams?: Params;

    public currentResort$: EventEmitter<Resort> = new EventEmitter();

    private resortsFromObject?: World | Continent | Country | State;
    public resortsFromObject$: EventEmitter<World | Continent | Country | State | undefined> = new EventEmitter();

    private _error: string | undefined;
    public get error(): string | undefined { return this._error; }
    public set error(value: string | undefined) {
        this._error = value;
        this._error$.emit(this.error);
    }

    private _error$: EventEmitter<string> = new EventEmitter();
    public get error$() { return this._error$; }

    searchEngine?: SearchEngine;

    private resortsCache: LRUCache<number, Resort>
        = new LRUCache<number, Resort>({ max: 10 });

    constructor(
        private router: Router,
        private route: ActivatedRoute,

        private backend: BackendService,
        private utils: UtilsService,
        private url: UrlService,
        private window: WindowService,
        // private location: Location,
    ) {
        console.log("ResortsService constructor");

        this.backend.fetchAllResorts().subscribe(
            allResorts => this.initWithWorld(allResorts)
        );

        this.route.queryParams.subscribe(
            params => this.queryParams = params);

        this.router.events.pipe(
            filter((event: Event) => event instanceof NavigationEnd)
        ).subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                let url = (event as NavigationEnd).url;
                console.log("ResortsService NavigationEnd url", url);
                console.log("ResortsService NavigationEnd world", this._world);
                this.processResorts(url);
            }
        });

        // this.router.events.pipe(
        //     filter((event: Event) => event instanceof NavigationStart)
        // ).subscribe((event: Event) => {
        //     if (event instanceof NavigationStart) {
        //         let url = (event as NavigationStart).url;
        //         console.log("ResortsService NavigationStart", url);
        //         this.processResorts(url);
        //     }
        // });
    }

    private initWithWorld(allResorts: AllResorts2) {
        this._world = new World(allResorts);
        this.searchEngine = new SearchEngine(this._world);
        this.world$.emit(this._world);
        this.processResorts(this.router.url);
    }

    private processResorts(topurl: string) {
        if (!this._world) {
            return;
        }

        let resortIndex = topurl.indexOf('/resort');
        if (resortIndex == -1) {
            return;
        }
        let url = topurl.substring(resortIndex);
        console.log("ResortsService looking at url", topurl);
        let parsed = this.utils.parsedUrlFrom(url, { world: this.world });
        console.log("ResortsService Got ParsedUrl", parsed);

        console.log("ResortsService emit", this.resortsFromObject);
        this.resortsFromObject = parsed.resortsFromObject;
        this.resortsFromObject$.emit(this.resortsFromObject);

        if (parsed.error) {
            this.error = parsed.error;
        } else {
            let resortBrief: ResortBrief = parsed.resortBrief!;
            if (resortBrief) {
                console.log("ResortsService Resort brief", resortBrief);
                console.log("ResortsService Resort", this._resort);

                if (parsed.urlUsesPreviousName) {
                    let url = this.url.urlForResort(resortBrief);
                    let extras = {};
                    if (this.queryParams) {
                        extras = { queryParams: this.queryParams };
                    }
                    console.log("Old name for a ski resort found, redirecting",
                        "to the new one", url);
                    this.router.navigate([url], extras);
                    return;
                }
                if (!this._resort ||
                    (this._resort && resortBrief.resortUid != this._resort.resortUid)) {
                    this._resort = undefined;
                    console.log("Fetching new ski resort for",
                        resortBrief.resortUid);
                    this.setCurrentResortForResortUid(resortBrief.resortUid);
                }
            } else {
                this._resort = undefined;
                this.currentResort$.emit(this._resort);
            }
        }
    }

    public setCurrentResortForResortUid(
        resortUid: number, updateUrlDontFitBounds: boolean = false) {
        this.fetchResort(resortUid).subscribe(
            (resort: Resort | undefined) => {
                this._resort = resort;
                if (resort) {
                    let deviceTypeUrlPart = this.window.isSmallScreen() ? '/maps' : '';
                    let url = this.url.urlForModelObject(resort, deviceTypeUrlPart);
                    console.log("ResortsService set location URL to", url);
                    // this.location.replaceState(url);
                    // this.router.navigate([url], {
                    //     queryParams: this.url.getQueryParams(),
                    // });
                    if (updateUrlDontFitBounds) {
                        // window.history.pushState(window.history.state, "", url);
                        // this.location.replaceState(url, this.url.getQueryParamsAsString());
                        // this.url.updateQueryParams({ FOCUS: 0 });
                        console.log("ResortsService setCurrentResortForResortUid navigate to", url, "params", this.url.getQueryParams());
                        this.router.navigate([url], {
                            queryParams: this.url.getQueryParams(),
                            state: { FOCUS: 0 },
                        });
                    }
                }
                this.currentResort$.emit(this._resort);
            });
    }

    // maybeEmitCurrentResort() {
    //     this.currentResort$.emit(this._resort);
    // }

    // maybeEmitWorld() {
    //     this.world$.emit(this._world);
    // }

    maybeEmitResortsFromObject() {
        this.resortsFromObject$.emit(this.resortsFromObject);
    }

    fetchResort(resortUid: number): Observable<Resort | undefined> {
        let resort: Resort | undefined = this.resortsCache.get(resortUid);
        if (resort) {
            return of(resort);
        }

        const parseResponse = map(
            (response: GetResortsResponse2) =>
                this.parseFetchSingleResortResponse(response)
        );
        return parseResponse(
            this.backend.fetchResort(resortUid)
        );
    }

    private parseFetchSingleResortResponse(response: GetResortsResponse2): Resort | undefined {
        if (response.error) {
            this.error = response.error;
        } else if (response.resort) {
            let resort = new Resort(response.resort, this.world!);
            if (response.hasHeatmap != undefined) {
                resort.hasHeatmap = response.hasHeatmap;
            }
            resort.updateWithWeatherAndSnow(response);
            this.resortsCache.set(resort.resortUid, resort);
            return resort;
        }
        return undefined;
    }
}
