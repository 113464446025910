// -*-text-*-
import { Injectable } from '@angular/core';
import {
    HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders,
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType,
} from '@angular/common/http';
import { Observable, throwError, Subscription, pipe } from 'rxjs';
import { map, concatMap, filter } from 'rxjs/operators';
import {
    EventEmitter,
} from '@angular/core';

import {XSRFService} from '../xsrf.service';

import { ProgressBarService, ProgressReport } from "../progress-bar.service";


  
import {
    
      GPSPoint,
    
      GPSPointExplicitArray,
    
      GPSPointMappingArray,
    
      Point2D,
    
      GpsPointMapping,
    
      GPSPointArray,
    
      DecimatedLine,
    
      Resort,
    
      ResortTrace,
    
      WeatherStationObservation,
    
      ResortSnow,
    
} from './common';
  

  
import {
    
      Empty,
    
      AllResorts2,
    
      AllResortBoundaries,
    
      UserRequest,
    
      UserResponse,
    
      GetResortsResponse2,
    
      CheckoutSessionRequest,
    
      CheckoutSessionResponse,
    
      PurchaseCompletedRequest,
    
      PurchaseCompletedResponse,
    
      GetResortsRequest,
    
      GetResortsResponse,
    
      GetHeatmapRequest,
    
      GetHeatmapResponse,
    
      DeleteUserResponse,
    
} from './requests';
  

  



@Injectable({
    providedIn: 'root'
})
export class Webapi2Client {
  private baseUrl: string = "";
  private extra: string = '';

  private httpHeaders: HttpHeaders = new HttpHeaders({
          'Content-Type': 'application/octet-stream'
        });

  private httpOptions = {
          headers: this.httpHeaders,
          observe: 'response' as 'response',
          responseType: 'arraybuffer' as 'arraybuffer',
          withCredentials: true,
      };
  private httpOptionsProgress = {
          headers: this.httpHeaders,
          observe: 'events' as 'events',
          responseType: 'arraybuffer' as 'arraybuffer',
          reportProgress: true,
          withCredentials: true,
      };

  constructor(
      private http: HttpClient,
      private progressService: ProgressBarService,
      private xsrfService: XSRFService,
  ) {
  }

  setBaseUrl(url: string, extra?: string) {
    if (!url.endsWith("/")) {
      this.baseUrl = url + '/';
    } else {
      this.baseUrl = url;
    }
    if (extra) {
      if (!extra.endsWith('/')) {
        extra += '/';
      }
      this.extra = extra;
    }
  }

  getBaseUrl() { return this.baseUrl; }

  
    
    
  FetchAllResorts(request: Empty) : Observable<AllResorts2> {
    let url: string = this.baseUrl + this.extra + "fetch-all-resorts";
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = AllResorts2.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took AllResorts2 took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("FetchAllResorts Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  FetchAllResortsProgress(request: Empty) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "fetch-all-resorts";
    // let encodedBuffer: Buffer = Empty.encode(request).finish();
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = AllResorts2.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took AllResorts2 took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("FetchAllResorts Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  FetchAllResortsProgressUpdate(request: Empty) : Observable<AllResorts2> {
    let url: string = this.baseUrl + this.extra + "fetch-all-resorts";
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `FetchAllResorts-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = AllResorts2.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took AllResorts2 took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as AllResorts2
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("FetchAllResorts Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
    
    
  FetchResort(request: GetResortsRequest) : Observable<GetResortsResponse2> {
    let url: string = this.baseUrl + this.extra + "fetch-resort";
    let encodedBuffer: Uint8Array = GetResortsRequest.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = GetResortsResponse2.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took GetResortsResponse2 took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("FetchResort Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  FetchResortProgress(request: GetResortsRequest) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "fetch-resort";
    // let encodedBuffer: Buffer = GetResortsRequest.encode(request).finish();
    let encodedBuffer: Uint8Array = GetResortsRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = GetResortsResponse2.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took GetResortsResponse2 took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("FetchResort Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  FetchResortProgressUpdate(request: GetResortsRequest) : Observable<GetResortsResponse2> {
    let url: string = this.baseUrl + this.extra + "fetch-resort";
    let encodedBuffer: Uint8Array = GetResortsRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `FetchResort-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = GetResortsResponse2.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took GetResortsResponse2 took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as GetResortsResponse2
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("FetchResort Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
    
    
  FetchHeatmap(request: GetHeatmapRequest) : Observable<GetHeatmapResponse> {
    let url: string = this.baseUrl + this.extra + "fetch-heatmap";
    let encodedBuffer: Uint8Array = GetHeatmapRequest.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = GetHeatmapResponse.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took GetHeatmapResponse took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("FetchHeatmap Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  FetchHeatmapProgress(request: GetHeatmapRequest) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "fetch-heatmap";
    // let encodedBuffer: Buffer = GetHeatmapRequest.encode(request).finish();
    let encodedBuffer: Uint8Array = GetHeatmapRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = GetHeatmapResponse.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took GetHeatmapResponse took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("FetchHeatmap Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  FetchHeatmapProgressUpdate(request: GetHeatmapRequest) : Observable<GetHeatmapResponse> {
    let url: string = this.baseUrl + this.extra + "fetch-heatmap";
    let encodedBuffer: Uint8Array = GetHeatmapRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `FetchHeatmap-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = GetHeatmapResponse.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took GetHeatmapResponse took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as GetHeatmapResponse
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("FetchHeatmap Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
    
    
  FetchBoundaries(request: Empty) : Observable<AllResortBoundaries> {
    let url: string = this.baseUrl + this.extra + "fetch-boundaries";
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = AllResortBoundaries.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took AllResortBoundaries took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("FetchBoundaries Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  FetchBoundariesProgress(request: Empty) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "fetch-boundaries";
    // let encodedBuffer: Buffer = Empty.encode(request).finish();
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = AllResortBoundaries.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took AllResortBoundaries took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("FetchBoundaries Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  FetchBoundariesProgressUpdate(request: Empty) : Observable<AllResortBoundaries> {
    let url: string = this.baseUrl + this.extra + "fetch-boundaries";
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `FetchBoundaries-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = AllResortBoundaries.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took AllResortBoundaries took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as AllResortBoundaries
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("FetchBoundaries Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
}
