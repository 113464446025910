<header>
  <app-navbar [showExtraLinks]="true"></app-navbar>
  <app-progress-bar></app-progress-bar>
</header>

<div class="content">
  <div class="container-fluid">
    <h3 class="text-danger">This page does not exist!</h3>
  </div>
</div>

<footer>
  <app-footer></app-footer>
</footer>
