<div class="space-below">
  <ngb-progressbar *ngIf="show"
                   type="primary"
                   [value]="percentage"
                   [striped]="true"
                   [animated]="true"
                   height="10px"
                   >
  </ngb-progressbar>
  <div style="width: 100%; height: 10px" *ngIf="!show"></div>
</div>
