// -*-text-*-
import { Injectable } from '@angular/core';
import {
    HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders,
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType,
} from '@angular/common/http';
import { Observable, throwError, Subscription, pipe } from 'rxjs';
import { map, concatMap, filter } from 'rxjs/operators';
import {
    EventEmitter,
} from '@angular/core';

import {XSRFService} from '../xsrf.service';

import { ProgressBarService, ProgressReport } from "../progress-bar.service";


  
import {
    
      GPSPoint,
    
      GPSPointExplicitArray,
    
      GPSPointMappingArray,
    
      Point2D,
    
      GpsPointMapping,
    
      GPSPointArray,
    
      DecimatedLine,
    
      Resort,
    
      ResortTrace,
    
      WeatherStationObservation,
    
      ResortSnow,
    
} from './common';
  

  
import {
    
      Empty,
    
      AllResorts2,
    
      AllResortBoundaries,
    
      UserRequest,
    
      UserResponse,
    
      GetResortsResponse2,
    
      CheckoutSessionRequest,
    
      CheckoutSessionResponse,
    
      PurchaseCompletedRequest,
    
      PurchaseCompletedResponse,
    
      GetResortsRequest,
    
      GetResortsResponse,
    
      GetHeatmapRequest,
    
      GetHeatmapResponse,
    
      DeleteUserResponse,
    
} from './requests';
  

  



@Injectable({
    providedIn: 'root'
})
export class Webapi2authClient {
  private baseUrl: string = "";
  private extra: string = '';

  private httpHeaders: HttpHeaders = new HttpHeaders({
          'Content-Type': 'application/octet-stream'
        });

  private httpOptions = {
          headers: this.httpHeaders,
          observe: 'response' as 'response',
          responseType: 'arraybuffer' as 'arraybuffer',
          withCredentials: true,
      };
  private httpOptionsProgress = {
          headers: this.httpHeaders,
          observe: 'events' as 'events',
          responseType: 'arraybuffer' as 'arraybuffer',
          reportProgress: true,
          withCredentials: true,
      };

  constructor(
      private http: HttpClient,
      private progressService: ProgressBarService,
      private xsrfService: XSRFService,
  ) {
  }

  setBaseUrl(url: string, extra?: string) {
    if (!url.endsWith("/")) {
      this.baseUrl = url + '/';
    } else {
      this.baseUrl = url;
    }
    if (extra) {
      if (!extra.endsWith('/')) {
        extra += '/';
      }
      this.extra = extra;
    }
  }

  getBaseUrl() { return this.baseUrl; }

  
    
    
  FetchUser(request: Empty) : Observable<UserResponse> {
    let url: string = this.baseUrl + this.extra + "fetch-user";
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = UserResponse.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took UserResponse took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("FetchUser Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  FetchUserProgress(request: Empty) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "fetch-user";
    // let encodedBuffer: Buffer = Empty.encode(request).finish();
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = UserResponse.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took UserResponse took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("FetchUser Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  FetchUserProgressUpdate(request: Empty) : Observable<UserResponse> {
    let url: string = this.baseUrl + this.extra + "fetch-user";
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `FetchUser-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = UserResponse.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took UserResponse took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as UserResponse
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("FetchUser Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
    
    
  SaveUser(request: UserRequest) : Observable<UserResponse> {
    let url: string = this.baseUrl + this.extra + "save-user";
    let encodedBuffer: Uint8Array = UserRequest.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = UserResponse.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took UserResponse took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("SaveUser Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  SaveUserProgress(request: UserRequest) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "save-user";
    // let encodedBuffer: Buffer = UserRequest.encode(request).finish();
    let encodedBuffer: Uint8Array = UserRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = UserResponse.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took UserResponse took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("SaveUser Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  SaveUserProgressUpdate(request: UserRequest) : Observable<UserResponse> {
    let url: string = this.baseUrl + this.extra + "save-user";
    let encodedBuffer: Uint8Array = UserRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `SaveUser-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = UserResponse.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took UserResponse took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as UserResponse
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("SaveUser Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
    
    
  CreateCheckoutSession(request: CheckoutSessionRequest) : Observable<CheckoutSessionResponse> {
    let url: string = this.baseUrl + this.extra + "create-checkout-session";
    let encodedBuffer: Uint8Array = CheckoutSessionRequest.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = CheckoutSessionResponse.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took CheckoutSessionResponse took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("CreateCheckoutSession Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  CreateCheckoutSessionProgress(request: CheckoutSessionRequest) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "create-checkout-session";
    // let encodedBuffer: Buffer = CheckoutSessionRequest.encode(request).finish();
    let encodedBuffer: Uint8Array = CheckoutSessionRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = CheckoutSessionResponse.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took CheckoutSessionResponse took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("CreateCheckoutSession Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  CreateCheckoutSessionProgressUpdate(request: CheckoutSessionRequest) : Observable<CheckoutSessionResponse> {
    let url: string = this.baseUrl + this.extra + "create-checkout-session";
    let encodedBuffer: Uint8Array = CheckoutSessionRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `CreateCheckoutSession-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = CheckoutSessionResponse.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took CheckoutSessionResponse took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as CheckoutSessionResponse
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("CreateCheckoutSession Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
    
    
  PurchaseCompleted(request: PurchaseCompletedRequest) : Observable<PurchaseCompletedResponse> {
    let url: string = this.baseUrl + this.extra + "purchase-completed";
    let encodedBuffer: Uint8Array = PurchaseCompletedRequest.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = PurchaseCompletedResponse.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took PurchaseCompletedResponse took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("PurchaseCompleted Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  PurchaseCompletedProgress(request: PurchaseCompletedRequest) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "purchase-completed";
    // let encodedBuffer: Buffer = PurchaseCompletedRequest.encode(request).finish();
    let encodedBuffer: Uint8Array = PurchaseCompletedRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = PurchaseCompletedResponse.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took PurchaseCompletedResponse took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("PurchaseCompleted Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  PurchaseCompletedProgressUpdate(request: PurchaseCompletedRequest) : Observable<PurchaseCompletedResponse> {
    let url: string = this.baseUrl + this.extra + "purchase-completed";
    let encodedBuffer: Uint8Array = PurchaseCompletedRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `PurchaseCompleted-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = PurchaseCompletedResponse.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took PurchaseCompletedResponse took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as PurchaseCompletedResponse
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("PurchaseCompleted Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
    
    
  PurchaseCancelled(request: PurchaseCompletedRequest) : Observable<PurchaseCompletedResponse> {
    let url: string = this.baseUrl + this.extra + "purchase-cancelled";
    let encodedBuffer: Uint8Array = PurchaseCompletedRequest.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = PurchaseCompletedResponse.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took PurchaseCompletedResponse took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("PurchaseCancelled Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  PurchaseCancelledProgress(request: PurchaseCompletedRequest) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "purchase-cancelled";
    // let encodedBuffer: Buffer = PurchaseCompletedRequest.encode(request).finish();
    let encodedBuffer: Uint8Array = PurchaseCompletedRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = PurchaseCompletedResponse.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took PurchaseCompletedResponse took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("PurchaseCancelled Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  PurchaseCancelledProgressUpdate(request: PurchaseCompletedRequest) : Observable<PurchaseCompletedResponse> {
    let url: string = this.baseUrl + this.extra + "purchase-cancelled";
    let encodedBuffer: Uint8Array = PurchaseCompletedRequest.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `PurchaseCancelled-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = PurchaseCompletedResponse.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took PurchaseCompletedResponse took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as PurchaseCompletedResponse
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("PurchaseCancelled Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
    
    
  DeleteUser(request: Empty) : Observable<DeleteUserResponse> {
    let url: string = this.baseUrl + this.extra + "delete-user";
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    // this.setupHttpHeaders();
    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpResponse<ArrayBuffer>) => {
      let content: Uint8Array = new Uint8Array(response.body!);
      // let start = performance.now();
      let result = DeleteUserResponse.fromBinary(content);
      // let end = performance.now();
      // let duration = end - start;
      // console.log(`Parsing took DeleteUserResponse took ${duration} milliseconds`);
      return result;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptions
    ));
    // console.log("DeleteUser Will send request with options", this.httpOptions);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  DeleteUserProgress(request: Empty) : Observable<HttpEvent<ArrayBuffer>> {
    let url: string = this.baseUrl + this.extra + "delete-user";
    // let encodedBuffer: Buffer = Empty.encode(request).finish();
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    const parseHttpResponse = map((response: HttpEvent<ArrayBuffer>) => {
      if (response.type == HttpEventType.Response && response.body) {
        let content: Uint8Array = new Uint8Array(response.body!);
        // let start = performance.now();

        (response as any).parsedbody = DeleteUserResponse.fromBinary(content);
        // let end = performance.now();
        // let duration = end - start;
        // console.log(`Parsing took DeleteUserResponse took ${duration} milliseconds`);
      }
      return response;
    });

    let methodObs = parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ));
    // console.log("DeleteUser Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }


  DeleteUserProgressUpdate(request: Empty) : Observable<DeleteUserResponse> {
    let url: string = this.baseUrl + this.extra + "delete-user";
    let encodedBuffer: Uint8Array = Empty.toBinary(request);

    this.httpHeaders.set('Content-Length', '' + encodedBuffer.length);

    let id = `DeleteUser-${Date.now()}`;
    let downloadProgress: ProgressReport = new ProgressReport(id);

    const parseHttpResponse = map(
        (event: HttpEvent<ArrayBuffer>) => {
            // console.log(`parseHttpResponse for request ${id}`, event);
            if (!event) {
              return event;
            }
            switch (event.type) {
                case HttpEventType.DownloadProgress: {
                    downloadProgress.current = event.loaded;
                    downloadProgress.total = event.total!;
                    // console.log(`Download progress for id ${id}: loaded ${event.loaded} out of ${event.total}`);
                    this.progressService.updateWork(downloadProgress);
                    break;
                }
                case HttpEventType.Response: {
                    downloadProgress.done = true;
                    this.progressService.updateWork(downloadProgress);
                    if ((event as any).body) {
                       let content: Uint8Array = new Uint8Array(event.body!);
                       // let start = performance.now();
                       (event as any).parsedbody = DeleteUserResponse.fromBinary(content);
                       // let end = performance.now();
                       // let duration = end - start;
                       // console.log(`Parsing took DeleteUserResponse took ${duration} milliseconds`);
                    }
                    break;
                }
            }
            return event;
        },
        (error: any) => {
            downloadProgress.done = true;
            this.progressService.updateWork(downloadProgress);
            console.error(`Got error: ${error}`);
        }
    );
    const parsedResponse = filter((event: HttpEvent<ArrayBuffer>) => {
      return (event as any).parsedbody !== undefined;
    });

    const extractProto = map(
      (event: HttpEvent<ArrayBuffer>) => (event as any).parsedbody as DeleteUserResponse
    );

    let methodObs = extractProto(parsedResponse(parseHttpResponse(this.http.post(
        url,
        encodedBuffer.buffer.slice(0, encodedBuffer.length),
        this.httpOptionsProgress
    ))));
    // console.log("DeleteUser Will send request with options", this.httpOptionsProgress);

    return this.xsrfService.maybeFetchXsrfThenInvoke(methodObs);
  }

  
}
