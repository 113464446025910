import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NavbarComponent } from './navbar/navbar.component';
import { LoginButtonComponent } from './login/loginbutton.component';
import { FooterComponent } from './footer.component';
import { ProgressBarComponent } from './progress-bar.component';
import { BreadcrumbComponent } from './skiresorts/breadcrumb.component';
import { SortableTableComponent } from './utils/sortable-table.component';
import { AppButtonsComponent } from './utils/app-buttons.component';
import { AllLoginButtonsComponent } from './login/all-login-buttons.component';

import { AdsComponent } from './utils/ads.component';

import {
    ElevationPipe, DistancePipe, TemperaturePipe, SnowDepthPipe, SpeedPipe
} from './utils/pipes.pipe';

import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import {
    chevronRight, arrowDown, arrowUp, search,
} from 'ngx-bootstrap-icons';

const icons = {
    arrowDown,
    arrowUp,
    chevronRight,
    search,
};


@NgModule({
    declarations: [
        NavbarComponent,
        LoginButtonComponent,
        FooterComponent,
        ProgressBarComponent,
        BreadcrumbComponent,
        SortableTableComponent,
        AppButtonsComponent,

        ElevationPipe,
        DistancePipe,
        SpeedPipe,
        TemperaturePipe,
        SnowDepthPipe,

        AdsComponent,
        AllLoginButtonsComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        NgbModule,

        // Note: Never call a forRoot static method in the
        // SharedModule. You might end up with different instances of
        // the service in your injector tree. But you can use forChild
        // if necessary.
        //
        // https://github.com/ngx-translate/core

        // AngularSvgIconModule.forRoot(),
        AngularSvgIconModule,
        NgxBootstrapIconsModule.pick(icons),
    ],

    exports: [
        NavbarComponent,
        LoginButtonComponent,
        FooterComponent,
        ProgressBarComponent,
        BreadcrumbComponent,
        SortableTableComponent,
        AppButtonsComponent,

        ElevationPipe,
        DistancePipe,
        SpeedPipe,
        TemperaturePipe,
        SnowDepthPipe,

        AdsComponent,

        RouterModule,
        CommonModule,
        NgbModule,
    ],

    providers: [
        // Listing services here will tell Angular to create new //
        // instances of them in any newly lazy-loaded module. In the
        // cases of services defined in this module, we don't really
        // want this to happen.
    ],
})
export class SharedModule { }
