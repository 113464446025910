import { plainToClass } from 'class-transformer';
import 'reflect-metadata';

import { Injectable, EventEmitter } from '@angular/core';

// Local storage support from https://github.com/cipchk/angular-web-storage
import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { BackendService } from "./backend.service";
import { User } from './datamodel';
import { UnitsService } from './prefs/units.service';
import { UserResponse } from "./proto/requests";
// import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _user?: User;
    public get user(): User | undefined { return this._user; }
    public set user(value: User | undefined) {
        this._user = value;
        if (value) {
            console.log("AuthService user", value instanceof User, value);
            value.sortAccounts();
            // this.fetchUser();
        }
        this._user$.emit(value);
        if (!this._user) {
            this.extraRoutes = [];
        }
    }

    private _user$: EventEmitter<User> = new EventEmitter();
    public get user$(): EventEmitter<User> { return this._user$; }

    private _extraRoutes?: string[] = [];
    public get extraRoutes() { return this._extraRoutes; }
    public set extraRoutes(value: any) {
        this._extraRoutes = value;
        this.extraRoutes$.emit(value);
    }

    private _extraRoutes$: EventEmitter<string[]> = new EventEmitter();
    public get extraRoutes$() { return this._extraRoutes$; }

    private _extraMenuTitle: string = '';
    public get extraMenuTitle(): string { return this._extraMenuTitle; }
    public set extraMenuTitle(value: string) {
        this._extraMenuTitle = value;
        this.extraMenuTitle$.emit(value);
    }

    private _extraMenuTitle$: EventEmitter<string> = new EventEmitter();
    public get extraMenuTitle$() { return this._extraMenuTitle$; }

    private _numCreditsAvailable?: number;
    public get numCreditsAvailable() { return this._numCreditsAvailable; }

    private _downloadedResortUids: number[] = [];
    public get downloadedResortUids() { return this._downloadedResortUids; }

    private _creditsChanged$: EventEmitter<number> = new EventEmitter();
    public get creditsChanged$() { return this._creditsChanged$; }

    private _unitsChanged$: EventEmitter<string> = new EventEmitter<string>();
    get unitsChanged$() { return this._unitsChanged$; }

    constructor(
        private backend: BackendService,
        private storage: LocalStorageService,
        // private utils: UtilsService,
    ) {
        this.storage.observe('user').subscribe(
            (user: User) => this.user = plainToClass(User, user)
        );
        this.storage.observe('routes').subscribe(
            (routes: any) => this.extraRoutes = routes
        );
        this.storage.observe('extraMenuTitle').subscribe(
            (title: string) => this.extraMenuTitle = title
        );
        // The 'userLoggedIn' storage value is set when a user logs
        // in. This storage value is the only way the non-Angular
        // login authentication schemes can interact with the Angular
        // app: upon a successful login, the 'userLoggedIn' storage is
        // set outside of our Angular app. See
        // server/web/templates/oauth-response.html on how this is done.
        this.storage.observe('userLoggedIn').subscribe(
            (value: boolean) => {
                if (value) {
                    this.fetchUser();
                }
            }
        );
        this.user = plainToClass(User, this.storage.retrieve('user'));
        if (this.user) {
            this.fetchUser();
        }
        this.extraRoutes = this.storage.retrieve('routes') || [];
        this.extraMenuTitle = this.storage.retrieve('extraMenuTitle');
        console.log("AuthService constructor: user", this.user);
    }

    getUserEventEmitter(): EventEmitter<User> {
        return this._user$;
    }

    maybeEmitNumberOfCreditsAvailable() {
        this._creditsChanged$.emit(this._numCreditsAvailable);
    }

    private handleUser(user: User) {
        console.log("AuthService User", this.user);
        user = plainToClass(User, user);
        // this.user = user;
        // console.log("User transformed", this.user);
        console.log("Will store user", user);
        this.storage.store('user', user);
        console.log("Will store userLoggedIn", user);
        this.storage.store('userLoggedIn', true);
    }

    fetchUser() {
        console.log("Making request to fetchUser");
        this.backend.fetchUser().subscribe(
            (response: UserResponse) => {
                console.log("Got fetchUser response", response);
                this.updateFromUserResponse(response);
                if (response.extraRoutes) {
                    this.storage.store('routes', response.extraRoutes);
                }

                if (response.extraMenuTitle) {
                    this.storage.store('extraMenuTitle', response.extraMenuTitle);
                }

                // We fetched the user as a result of a login, so
                // clear the 'userLoggedIn'
                this.storage.clear('userLoggedIn');

                // this.extraRoutes = response.extraRoutes;
                console.log("User logged in, notify subscribers of changes",
                    this._user);
            },
            (err: any) => {
                console.log("Got an error, clearing the user", err);
                this.clearUser();
            }
        )
    }

    public updateFromUserResponse(response: UserResponse) {
        if (!this.user) {
            return;
        }
        if (response.smallImage && response.largeImage) {
            console.log("Updating the user images small", response.smallImage);
            // `data:${response.mimeType};base64,${this.utils.toBase64(response.smallImage)}`
            // let mimeType = response.mimeType;
            // let smallImageBase64 = this.utils.toBase64(response.smallImage);
            // let largeImageBase64 = this.utils.toBase64(response.largeImage);

            // console.log("Got mime type", mimeType);
            // console.log("Small image base64", smallImageBase64);
            // console.log("Large image base64", largeImageBase64);

            // `data:${mimeType};base64,${smallImageBase64}`
            this.user.image = response.smallImage;
            this.user.largeImage = response.largeImage;
        }


        this._numCreditsAvailable = response.numCreditsAvailable;
        this._downloadedResortUids = response.resortUid;
        console.log("Got numCreditsAvailable", this._numCreditsAvailable);


        if (response.distanceUnits) {
            this.user.distanceUnits = response.distanceUnits;
        }
        if (response.elevationUnits) {
            this.user.elevationUnits = response.elevationUnits;
        }
        if (response.temperatureUnits) {
            this.user.temperatureUnits = response.temperatureUnits;
        }

        console.log("Will store user", this.user);
        this.storage.store("user", this.user);
        this._user$.emit(this.user);
        this._creditsChanged$.emit(this._numCreditsAvailable);
        this._unitsChanged$.emit("user");
    }

    public updateCreditsAndResorts(numCredits: number, resortUids: number[]) {
        this._numCreditsAvailable = numCredits;
        this._downloadedResortUids = resortUids;
        this._creditsChanged$.emit(this._numCreditsAvailable);
    }

    // This method is called each time an RPC method that requires
    // authentication returns an error. It means the cookie has
    // expired, and the user needs to log back in.
    clearUser() {
        this.storage.clear('user');
        this.storage.clear('routes');
        this.storage.clear('extraMenuTitle');
        this.storage.clear('userLoggedIn');
        this.user = undefined;

        this._numCreditsAvailable = undefined;
        this._downloadedResortUids = [];

        this._user$.emit(this.user);
        this._creditsChanged$.emit(this._numCreditsAvailable);

        console.log("User was cleared from local storage");
    }

    signOut() {
        this.backend.handleLogout().subscribe((_: User) => {
            this.clearUser();
        });
    }

    // Google

    getGoogleUrl(): string {
        return this.backend.baseUrl + '/googlelogin';
    }

    googleSignIn() {
        let url = this.getGoogleUrl();
        let w = 800, h = 600;
        this.openPopup(url, w, h);
    }

    // Facebook

    getFacebookUrl(): string {
        return this.backend.baseUrl + '/facebooklogin';
    }

    facebookSignIn() {
        let url = this.getFacebookUrl();
        let w = 800, h = 600;
        this.openPopup(url, w, h);
    }

    // Apple

    appleSignIn() {
        let self = this;
        if ((window as any).appleConnectLoaded) {
            this._appleSignIn();
        } else {
            let appleConnectLoaded = (AppleID: any) => {
                console.log("Apple library loaded");
                (window as any).appleConnectLoaded = true;
                AppleID.auth.init({
                    clientId: "com.jollyturns.www",
                    scope: 'name email',
                    redirectURI: this.backend.appleLoginRedirectUrl(),
                    usePopup: true
                });
                self._appleSignIn();
            };

            (function(d, s, cb) {
                var js: any, fjs = d.getElementsByTagName(s)[0];
                js = d.createElement(s);
                js.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
                if (fjs && fjs!.parentNode) {
                    fjs.parentNode.insertBefore(js, fjs);
                }
                js.addEventListener("load", () => cb((window as any).AppleID));
            }(document, 'script', appleConnectLoaded));
        }
    }

    private async _appleSignIn() {
        try {
            const w = window as any;
            const data = await w.AppleID.auth.signIn();
            console.log("Got data", data);
            this.backend.handleAppleLogin(data)
                .subscribe(user => this.handleUser(user));
        } catch (error) {
            //handle error.
        }
        document.addEventListener('AppleIDSignInOnSuccess', (data) => {
            console.log("Apple signin success:", data);
            this.backend.handleAppleLogin(data)
                .subscribe(user => this.handleUser(user));
        });
        //Listen for authorization failures
        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            console.log("Could not sign in with Apple:", error);
        });
    }

    // Twitter

    getTwitterUrl(): string {
        return this.backend.baseUrl + '/twitterlogin';
    }

    twitterSignIn() {
        let url = this.getTwitterUrl();
        let w = 600, h = 400;
        this.openPopup(url, w, h);
    }


    // Microsoft

    getMicrosoftUrl(): string {
        return this.backend.baseUrl + '/microsoft-login';
    }

    microsoftSignIn() {
        let url = this.getMicrosoftUrl();
        let w = 600, h = 400;
        this.openPopup(url, w, h);
    }

    private openPopup(url: string, w: number, h: number) {
        let width = window.outerWidth, height = window.outerHeight;
        let x = window.screenLeft + (width - w) / 2,
            y = window.screenTop + (height - h) / 4;

        window.open(url, "_blank", `width=${w},height=${h},left=${x},top=${y}`);
    }
}
