// This is used for deployment on dev.jollyturns.com

export const hostname = 'dev.jollyturns.com';

export const environment = {
    production: true,

    mapbox: {
        style: "ovidiu/cl920ysph000015mpmerwfose",
        // style: "ovidiu/cl90z6w1q002u16o7bhza3303",
        // style: "ovidiu/ckq7cdsof07c017s8ejzqoy0i",
        accessToken: 'pk.eyJ1Ijoib3ZpZGl1IiwiYSI6ImNrcThod2NnaTBnMG8yd3FwcHJ2MWY2amsifQ.Y-6DomxAKTYnCCE5G9U7ig',
    },

    baseUrl: `https://${hostname}/webapi2`,
    tilesUrl: `https://${hostname}/webapi2/tiles/v1`,
    heatmapsUrl: `https://${hostname}/webapi2/heatmaps/v1`,

    showWindowSize: true,

    showTriangulation: false,
    showBoundaries: true,

    matomoSiteId: 3,

    googlePublisherId: "ca-pub-4777089745763437",
    googleAnalytics: "UA-74588310-1",
    googleAnalytics4: "G-JVEFCWC03E",
    responsiveAdUnit: "3214141145",

    stripeKey: "pk_test_51JF53mAIu3isvLDKZp5EtfCmJ1tAxTNncu3hBAp0HVMuNbp5okpeLH4RINF6Xzra57R8NVl5fjYkJufNNG51QNYX00Nu3mIPy3",
};
