import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavbarService {

    // This a prefix to be prepended to URL link results from the
    // search box. Different submodules of the app can set this up so
    // that search results will point into the submodule.
    //
    // Example: '/snow' for the snow data entry module.
    public searchUrlPrefix: string = '';

    constructor() { }
}
