import { NgModule } from '@angular/core';
import { UrlSerializer } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import {
    HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS,
    HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { SharedModule } from './shared.module';

import { hostname, environment } from '../environments/environment';
import { PageNotFoundComponent } from './page-not-found.component';

import { httpInterceptorProviders, XSRFService } from './xsrf.service';

import { CustomUrlSerializer } from './utils/custom-url-serializer';
import { MapboxCostsCalculatorComponent } from './utils/mapbox-costs-calculator.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';

// import { UnitsService } from './prefs/units.service';
// import { BackendService } from './backend.service';
// import { UtilsService } from './utils.service';
// import { UrlService } from './utils/url.service';
// import { NavbarService } from './navbar/navbar.service';
// import { AuthService } from './auth.service';
// import { ResortsService } from './resorts.service';
// import { AnchorService } from './anchor.service';
// import { ProgressBarService } from './progress-bar.service';
// import { LazyLoaderService } from './lazy-loader.service';

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,

        MapboxCostsCalculatorComponent,
    ],
    imports: [
        BrowserModule,

        // MatomoModule.forRoot({
        //     scriptUrl: `https://${hostname}/piwik/piwik.js`,
        //     trackers: [
        //         {
        //             trackerUrl: `https://${hostname}/piwik/piwik.php`,
        //             siteId: environment.matomoSiteId,
        //         }
        //     ],
        //     routeTracking: {
        //         enable: true
        //     }
        // }),

        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSvgIconModule.forRoot(),
        NgxWebstorageModule.forRoot({
            prefix: 'jollyturns',
            separator: '.',
            caseSensitive: true
        }),

        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: '_xsrf',
            headerName: 'X-XSRFToken',
        }),

        SharedModule,
        NgxMatomoTrackerModule.forRoot({
            trackerUrl: `https://${hostname}/piwik`,
            siteId: environment.matomoSiteId,
            disabled: !environment.production,
        }),
        NgxMatomoRouterModule,
    ],
    providers: [
        Title,
        DatePipe,
        CookieService,
        httpInterceptorProviders,

        // XSRFService,
        // UnitsService,
        // BackendService,
        // UtilsService,
        // UrlService,
        // NavbarService,
        // AuthService,
        // ResortsService,
        // AnchorService,
        // ProgressBarService,
        // LazyLoaderService,

        { provide: UrlSerializer, useClass: CustomUrlSerializer },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
