// import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Event, Params, NavigationEnd, } from '@angular/router';

import '../types';
import {
    World, Continent, Country, State, ResortBrief, Resort,
    Lift, SkiRun, Restaurant
} from '../datamodel';
import { BackendService } from '../backend.service';
import { UtilsService, ParsedUrl } from '../utils.service';

export const LIFTS = 'vl';
export const RESTAURANTS = 'vR';
export const HEATMAP = 'vh';

// Possible Angular Router state values.
//
// Focus is used to prevent the map from automatically focusing on
// resort objects.
//
// By default each time we click a resort object we focus the
// mapbox view on it. However while panning the map view, we don't
// want to focus on new resorts as they appear in
// view. ResortsService.setCurrentResortForResortUid() sets the
// FOCUS state to 0, to prevent this from happening.
//
// Used to set MapboxService.shouldFocus.
export const FOCUS = 'focus';

@Injectable({
    providedIn: 'root'
})
export class UrlService {
    // Parameters:
    //
    // `show`: the type of statistics when viewing ski resorts in a
    //         country or state/region.
    // `b`: bearing
    // `p`: pitch
    // `x`: lng
    // `y`: lat
    // `z`: zoom
    // `f`: focus on the current ski resort. Default 1.
    //
    // Visibility parameters: turn on/off visibility for various
    // objects within a ski resort. The default is to show the
    // corresponding object. If the parameter is present, irrespective
    // of its value, it implies the corresponding object should be
    // hidden.
    //
    // `vl`: lifts
    // `vR`: restaurants
    // `vg`: ski runs - green
    // `vb`: ski runs - blue
    // `vB`: ski runs - black
    // `vBB`: ski runs - double black
    // `vBBB`: ski runs triple black
    // `vt`: ski runs - terrain park
    // `vr`: ski runs - red
    // `vo`: ski runs - orange
    // `vy`: ski runs - yellow
    //
    // `vh`: heatmap
    //
    // Map types:
    //
    // `m`: map type: 3d, or urlized name of the 2D map's label.
    //
    // Other:
    // `type`: used by the new snow manager
    //
    static allowedQueryParams = [
        'show', 'b', 'p', 'z', 'x', 'y',
        'vl', 'vR', 'vg', 'vb', 'vB', 'vBB', 'vBBB', 'vt', 'vr', 'vo', 'vy', 'vh',
        'm', 'type',
    ];

    // These are the only parameters we pass along while navigation to
    // a different resort.
    static allowedBetweenResortsQueryParams = [
        'type', 'show',
    ];

    static allowedBetweenResortObjectsQueryParams: Set<string> =
        new Set<string>(UrlService.allowedQueryParams.filter(
            (e: any) => e !== 'x' && e !== 'y' && e != 'z'
        ));

    private queryParams: Params = {};

    // Keeps track of the last components in the URL that indicate the
    // resort object or objects that are selected. For example:
    //
    // /resort/united-states-of-america/heavenly/skiruns-blue => skiruns-blue
    // /resort/united-states-of-america/heavenly/skirun/aries => skirun/aries
    //
    // This is used to compute the URL for the 3D and resort map(s) links.
    private _selectedObjectUrlPart: string = '.';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private zone: NgZone,
        private backend: BackendService,
        private utils: UtilsService,
        // private location: Location,
    ) {
        this.route.queryParams.subscribe(params => {
            console.log("UrlService queryParams changed", params);
            this.queryParams = params
                ? UrlService.allowedQueryParams.reduce(
                    (obj: any, key) => {
                        let value = params[key];
                        if (value) {
                            obj[key] = value;
                        }
                        return obj;
                    }, {})
                : {};

            console.log("UrlService: filtered query params", this.queryParams);
        });

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.updateResortObjectUrlPart(this.router.url);
            }
        });
        // This is used in the horizontal bar chart SVG code for navigation.
        (window as any).navigateTo = (event: any, url: string) => {
            event.preventDefault();
            console.log("Onclick handler, will navigate to", url);
            this.zone.run(() => this.router.navigateByUrl(url));
            return true;
        };
        console.log("UrlService allowedQueryParams",
            UrlService.allowedQueryParams);
        console.log("UrlService allowedBetweenResortObjectsQueryParams",
            UrlService.allowedBetweenResortObjectsQueryParams);
    }

    getQueryParams(): Params {
        return this.queryParams;
    }

    getQueryParamsAsString(): string {
        let searchParams = new URLSearchParams(this.queryParams);
        return searchParams.toString();
    }

    getQueryParamsNoXYZ(): Params {
        let params: Params = {};
        for (const [key, value] of Object.entries(this.queryParams)) {
            if (UrlService.allowedBetweenResortObjectsQueryParams.has(key)) {
                params[key] = value;
            }
        }

        // console.log("UrlService getQueryParamsNoXYZ queryParams",
        //     this.queryParams, "filtered", params);
        return params;
    }

    getQueryParamsForBetweenResortsNavigations(): Params {
        if (this.queryParams) {
            return UrlService.allowedBetweenResortsQueryParams.reduce(
                (obj: any, key) => {
                    let value = this.queryParams[key];
                    if (value) {
                        obj[key] = value;
                    }
                    return obj;
                }, {});
        } else {
            return {};
        }
    }

    getQueryMapType() {
        let params = this.getQueryParams();
        let mapType = params['m'] || '3d';
        return mapType;
    }

    getCampaignName() {
        return this.queryParams['utm_campaign'] || 'website';
    }

    // Updates _selectedObjectUrlPart on URL changes.
    private updateResortObjectUrlPart(path: string) {
        // console.log("URL service: router URL",
        //     this.router.url, this.backend.baseUrl);
        let parsed = new URL(path, this.backend.baseUrl);

        // Unfortunately the subscription on queryParams does not seem
        // to be invoked as expected, so we need to parse the query
        // params here.
        this.queryParams = {};
        let params = new URLSearchParams(parsed.search);
        for (const [key, value] of (params as any).entries()) {
            this.queryParams[key] = value;
        }

        // console.log("UrlService parsed URL", parsed);
        // console.log("UrlService queryParams", this.queryParams);
        let components = parsed.pathname.split('/');
        // console.log("Looking at URL components", components);
        if (components[1] == 'resort') {
            if (components.length >= 5 && components[4] == 'maps') {
                // /resort/united-states-of-america/heavenly/maps
                // /resort/united-states-of-america/heavenly/maps/lift/gondola
                components.splice(4, 1);
            }
            if (components.length >= 5) {
                // /resort/united-states-of-america/heavenly/lift/gondola
                this._selectedObjectUrlPart = components.splice(4).join('/');
            } else if (components.length >= 4) {
                // /resort/united-states-of-america/heavenly
                this._selectedObjectUrlPart = '.';
            }
            console.log("selectedObjectUrlPart", this._selectedObjectUrlPart,
                "query params", this.queryParams);
        }
    }

    updateQueryParams(params: { [name: string]: any }): void {
        this.queryParams = Object.assign({}, this.queryParams, params);
        // console.log("Update query params:", this.queryParams);
    }

    // Removes a list of keys from queryParams
    clearQueryParams(keys: string[]) {
        // console.log("clearQueryParams keys", keys,
        //     ", queryParams", this.queryParams);
        let params: Params = {};
        for (let key of Object.keys(this.queryParams)) {
            if (!keys.includes(key)) {
                params[key] = this.queryParams[key];
            }
        }
        this.queryParams = params;
        // console.log("Cleared query params", this.queryParams);
    }

    clearQueryParamsForListOfSkiResorts() {
        this.clearQueryParams([
            'b', 'p', 'x', 'y', 'z',
            'vl', 'vr', 'vg', 'vb', 'vB', 'vBB', 'vBBB', 'vt', 'vr', 'vo', 'vy', 'vh',
            'm', 'type',
        ]);
    }

    createQueryString() {
        let encodedParams = Object.entries(this.getQueryParams())
            .filter((e) => e[1] !== undefined)
            .map(
                (e) => encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1])
            )
            .join('&');
        return encodedParams;
    }

    hasXYZInQueryParams(): boolean {
        let lng = this.queryParams.x;
        let lat = this.queryParams.y;
        let zoom = this.queryParams.z;
        console.log("lat", lat, ", lng", lng, "zoom", zoom);
        return lat && lng && zoom;
    }

    clearXYZInQueryParams() {
        this.clearQueryParams(['x', 'y', 'z']);
    }

    showsMap3D(): boolean {
        return this.queryParams['m'] === '3d';
    }

    urlForParsedUrl(parsed: ParsedUrl) {
        let resort = parsed.resort;

        if (resort) {
            if (parsed.lift) {
                return this.urlForLift(resort, parsed.lift);
            } else if (parsed.skirun) {
                return this.urlForSkiRun(resort, parsed.skirun);
            } else if (parsed.restaurant) {
                return this.urlForRestaurant(resort, parsed.restaurant);
            } else if (parsed.resortBrief) {
                let resortBrief = parsed.resortBrief;
                let resortUrl = this.urlForResort(resortBrief);
                if (parsed.lifts) {
                    return resortUrl + '/lifts';
                } else if (parsed.skiruns && parsed.skirunsDifficulty) {
                    return resortUrl + this.utils.urlPartForSkiRunDifficulty(
                        parsed.skirunsDifficulty);
                } else if (parsed.restaurants) {
                    return resortUrl + '/restaurants';
                }
                return this.urlForResort(parsed.resortBrief);
            }
        } else if (parsed.resortsFromObject) {
            return this.urlForModelObject(parsed.resortsFromObject);
        }
        return '';
    }

    urlForWorld(prefix: string = '') {
        return prefix + '/resorts/';
    };

    urlForContinent(continent: Continent, prefix: string = '') {
        if (!continent) {
            return prefix + '/resorts/';
        }
        else {
            return prefix + '/resorts/continent/' + continent.name.urlize()
        }
    };

    urlForCountry(country: Country, prefix: string = '') {
        return prefix + '/resorts/country/' + country.name.urlize();
    };

    urlForState(state: State, prefix: string = '') {
        let country = state.country;
        return prefix + '/resorts/country/' + country.name.urlize() + '/' + state.name.urlize();
    };

    urlForResort(resort: ResortBrief, prefix: string = '') {
        let countryName: string = resort.country.name;
        // if (resort.state instanceof State) {
        //     let country: Country = resort.state.country;
        //     countryName = country.name;
        // } else {
        //     countryName = resort.country.name;
        // }
        return prefix + '/resort/' + countryName.urlize() + '/' + resort.name.urlize();
    };

    // selectedObjectsUrlPart is the end of the URL path used to
    // select a certain object or set of objects from the resort.
    urlForFullResort(resort: Resort, selectedObjectsUrlPart: string) {
        if (!selectedObjectsUrlPart) {
            selectedObjectsUrlPart = '';
        }
        // let encodedParams = this.createQueryString();
        let countryName = resort.country.name;
        return '/resort/' + countryName.urlize() + '/' +
            resort.name.urlize() + selectedObjectsUrlPart;
        // (encodedParams !== '' ? '?' + encodedParams : '');
    }

    urlForModelObject(object: any, prefix: string = '') {
        if (object instanceof World) {
            return this.urlForWorld(prefix);
        } else if (object instanceof Continent) {
            return this.urlForContinent(object, prefix);
        } else if (object instanceof Country) {
            return this.urlForCountry(object, prefix);
        } else if (object instanceof State) {
            return this.urlForState(object, prefix);
        } else if (object instanceof ResortBrief) {
            return this.urlForResort(object, prefix);
        } else if (object instanceof Resort) {
            return this.urlForFullResort(object, prefix);
        } else if (object.urlLink && typeof object.urlLink === "function") {
            return object.urlLink(this);
        } else {
            return prefix;
        }
    }

    urlForLifts(resort: Resort) {
        return this.urlForFullResort(resort, '/lifts');
    }

    urlForLift(resort: Resort, lift: Lift, mapPrefix: string = '') {
        let liftPart = mapPrefix + '/lift/' + lift.name.urlize();
        let url = this.urlForFullResort(resort, liftPart);
        // console.log("UrlService url for lift", url);
        return url;
    };

    urlForSkiRun(resort: Resort, skirun: SkiRun, mapPrefix: string = '') {
        let skirunPart = mapPrefix + '/skirun/' + skirun.name.urlize();
        return this.urlForFullResort(resort, skirunPart);
    };

    urlForRestaurant(resort: Resort, restaurant: Restaurant, mapPrefix: string = '') {
        let restaurantPart = mapPrefix + '/restaurant/' + restaurant.name.urlize();
        return this.urlForFullResort(resort, restaurantPart);
    };

    urlForSelectedResortObject() {
        // console.log("urlForSelectedResortObject");
        return this._selectedObjectUrlPart;
    }

    urlForUserImage(identifier: string) {
        return identifier ? `/webapi2/user/image/${identifier}` : '';
    }

    urlForUserThumbnailImage(identifier: string) {
        return identifier ? `/webapi2/user/image/${identifier}/small` : '';
    }
}
